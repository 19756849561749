import CSS from "csstype";

export const style: { [key: string]: CSS.Properties } = {
    page: {
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    column: {
        width: "50%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    content: {
        position: "relative",
        maxWidth: "497px",
        padding: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    logo: {
        position: "absolute",
        left: "40px",
        top:"133px"
    },
}