import React from "react";
import {style} from "./style";

type Props = {
    text: string,
    onClick?: () => void
    className?: string
}

export default class LoginSecondaryButton extends React.Component<Props, any> {
    render() {
        return (
            <button type={"button"} className={this.props.className} style={style}
                    onClick={this.props.onClick}>{this.props.text}</button>
        );
    }
}