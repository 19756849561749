import React from "react";
import {style} from "./style";

type Props = {
    text: string,
    className?: string
}

export default class LoginLabel extends React.Component<Props, any> {
    render() {
        return (
            <label className={this.props.className} style={style}>{this.props.text}</label>
        );
    }
}