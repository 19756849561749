import {Col, Row} from "react-bootstrap";
import {style} from "./style";
import LoginLabel from "../../../loginForm/components/LoginLabel/LoginLabel";
import LoginInput from "../../../loginForm/components/LoginInput/LoginInput";
import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import TokenManager from "../../../../common/TokenManager";
import Toast, {ApiError} from "../../../../common/Toast";
import {SelectionOptionsResponse} from "../../FilterByNew/types";
import {useNavigate} from "react-router-dom";
import InterestCheckBox from "../../../loginForm/InterestCheckBox/InterestCheckBox";
import Loader from "../../../../common/Loader/Loader";
import {useAppDispatch, useAppSelector} from "../../../../common/Redux/hooks";
import {setUserPicture} from "../../../../common/Redux/reducers/userPicture";
import {FilterContent} from "../../FilterByNew/FilterByNew";
import {colors} from "../../../../common/colors";

export default function Profile() {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [saveLoading, setSaveLoading] = React.useState<boolean>(false)
    const [firstName, setFirstName] = React.useState<string>("")
    const [lastName, setLastName] = React.useState<string>("")
    const [company, setCompany] = React.useState<string>("")
    const [jobTitle, setJobTitle] = React.useState<string>("")
    const [areas, setAreas] = React.useState<FilterContent[]>([])
    const [picture, setPicture] = React.useState<string>("")
    const [selectedAreas, setSelectedAreas] = React.useState<FilterContent[]>([])
    const [pictureFile, setPictureFile] = React.useState<File>()
    const navigate = useNavigate();
    const [preview, setPreview] = React.useState<string>()
    const dispatch = useAppDispatch()
    const userPicture = useAppSelector((state) => state.userPicture.value)


    const getAreas = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/selection-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            setLoading(false)
            Toast(data)
            return
        }
        let data: SelectionOptionsResponse = await fetched.json()
        setAreas(data.results["thematicAreas"])
        getUserData(data.results["thematicAreas"])
    }

    const getUserData = async (filters: FilterContent[]) => {
        if (userPicture.length > 0)
            setPicture(userPicture)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!fetched.ok) {
            setLoading(false)
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        setLoading(false)
        let user: {
            user_id: string,
            name: string,
            surname: string,
            email: string,
            job_title: string,
            company_name: string,
            profile_picture: string,
            interests: string[]
        } = await fetched.json()
        let interests: FilterContent[] = []
        for (const interest of user.interests) {
            let index = filters.findIndex((area) => area.name === interest)
            if (index !== -1)
                interests.push(filters[index])
        }
        setSelectedAreas(interests)
        setJobTitle(user.job_title ? user.job_title : "")
        setFirstName(user.name)
        setLastName(user.surname)
        setCompany(user.company_name)
        setPicture(user.profile_picture)
    }

    const saveUserData = async () => {
        setSaveLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        const formData = new FormData();
        formData.append("job_title", jobTitle);
        formData.append("interests", selectedAreas.map((area) => area.name).toString());
        if (pictureFile)
            formData.append("profile_picture", pictureFile)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        })
        if (!fetched.ok) {
            setSaveLoading(false)
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        if (preview)
            dispatch(setUserPicture(preview))
        setSaveLoading(false)
    }

    const manageArea = (selected: FilterContent) => {
        let index = selectedAreas.findIndex((area) => area.name === selected.name)
        if (index === -1) {
            let newAreas = [...selectedAreas]
            newAreas.push(selected)
            setSelectedAreas(newAreas)
        } else {
            let newAreas = [...selectedAreas]
            newAreas.splice(index, 1)
            setSelectedAreas(newAreas)
        }
    }

    useEffect(() => {
        getAreas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onJobTitleChange = (job: React.ChangeEvent<HTMLInputElement>) => {
        setJobTitle(job.target.value)
    }

    return (
        <div style={style.box}>
            {/*<div className={"d-flex justify-content-between align-items-center"} style={{marginBottom: "49px"}}>*/}
            {/*    <h3 style={style.title}>{"Il tuo profilo"}</h3>*/}
            {/*    <button style={jobTitle.length === 0 ? style.saveDisabled : style.save}*/}
            {/*            disabled={jobTitle.length === 0} onClick={saveUserData}>*/}
            {/*        {saveLoading ? <Loader size={16}/> : "Salva le modifiche"}*/}
            {/*    </button>*/}
            {/*</div>*/}
            {!loading ? <Row className={"w-100 d-flex justify-content-around"}>
                <Col style={{maxWidth: "600px"}}>
                    <div className={"d-flex justify-content-start align-items-center"} style={{marginBottom: "49px"}}>
                        <h3 style={style.title}>{"Il tuo profilo"}</h3>
                        {/*<button style={jobTitle.length === 0 ? style.saveDisabled : style.save}*/}
                        {/*        disabled={jobTitle.length === 0} onClick={saveUserData}>*/}
                        {/*    {saveLoading ? <Loader size={16}/> : "Salva le modifiche"}*/}
                        {/*</button>*/}
                    </div>
                    <h5 style={style.subtitle}>Dettagli</h5>
                    <div className={"d-flex flex-row align-items-center"} style={{marginBottom: "32px"}}>
                        {picture.length > 0 ?
                            preview ? <img style={style.userPicture} src={preview} alt={""}/>
                                : <img style={style.userPicture} src={picture} alt={""} onError={() => setPicture("")}/>
                            : <div style={style.userIcon}>
                                <FontAwesomeIcon icon={faUser} size={"2xl"}/>
                            </div>}
                        <div>
                            <label htmlFor="upload-photo"
                                   style={style.upload}>{"Caricare una nuova immagine del profilo"}</label>
                            <input type={"file"}
                                   id="upload-photo"
                                   onChange={(event) => {
                                       if (event.target.files?.length && event.target.files?.length > 0) {
                                           setPictureFile(event.target.files[0]);
                                           const objectUrl = URL.createObjectURL(event.target.files[0])
                                           setPreview(objectUrl)
                                       }
                                   }}
                                   style={{
                                       opacity: 0,
                                       position: "absolute",
                                       zIndex: "-1"
                                   }}/>
                        </div>
                    </div>
                    <div className={"d-flex flex-column"}>
                        <LoginLabel text={"Nome"}/>
                        <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={""}
                                    setValue={() => {
                                    }} value={firstName} disabled={true}/>
                        <LoginLabel text={"Cognome"}/>
                        <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={""}
                                    setValue={() => {
                                    }} value={lastName} disabled={true}/>
                        <LoginLabel text={"Company"}/>
                        <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={""}
                                    setValue={() => {
                                    }} value={company} disabled={true}/>
                        <LoginLabel text={"Professione"}/>
                        <LoginInput className={"mb32 unsetHW"} type={"text"} value={jobTitle}
                                    placeholder={"Qual è il tuo ruolo professionale?"}
                                    setValue={onJobTitleChange}/>
                    </div>
                </Col>
                <Col style={{maxWidth: "600px"}}>
                    <div className={"d-flex justify-content-end align-items-center"} style={{marginBottom: "49px"}}>
                        {/*<h3 style={style.title}>{"Il tuo profilo"}</h3>*/}
                        <button style={jobTitle.length === 0 ? style.saveDisabled : style.save}
                                disabled={jobTitle.length === 0} onClick={saveUserData}>
                            {saveLoading ? <Loader size={16}/> : "Salva le modifiche"}
                        </button>
                    </div>
                    <h5 style={style.subtitle}>{"Aree di interesse professionale"}</h5>
                    <div>
                        {areas.map((area, index) =>
                            <InterestCheckBox key={`area-${index}`}
                                              handleOnChange={() => manageArea(area)}
                                              text={area.name}
                                              checked={selectedAreas.map((area) => area.name).includes(area.name)}/>)}
                    </div>
                </Col>
                <p style={{textAlign: "center", marginTop: "16px"}}>Ti ricordiamo che per cancellare la tua utenza è
                    necessario inviare una mail a <a href={`mailto:info@point-out.net`} target="_blank"
                                                     rel={"noreferrer"}
                                                     style={{color: colors.supportBlack}}>info@point-out.net</a> indicando
                    nome, cognome e
                    azienda di appartenenza. Grazie!</p>
            </Row> : <Loader/>}

        </div>
    )
}