import React from "react";
import {style} from "./style";
import {Oval} from "react-loader-spinner";
import {colors} from "../../../../common/colors";

type Props = {
    text: string,
    onClick?: (params?: any) => void,
    className?: string,
    loading?: boolean,
    disabled?: boolean
}

export default class LoginButton extends React.Component<Props, any> {

    render() {
        if (this.props.loading)
            return (
                <div style={style.active} className={this.props.className}>
                    <Oval
                        height={30}
                        width={30}
                        color={colors.supportDarker}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor={colors.supportBlack}
                        strokeWidth={8}
                        strokeWidthSecondary={8}/>
                </div>
            )
        return (
            <button type={"submit"} className={this.props.className}
                    style={this.props.disabled ? style.disabled : style.active}
                    onClick={this.props.onClick?this.props.onClick:()=>{}} disabled={this.props.disabled}>{this.props.text}</button>
        );
    }
}