import CSS from "csstype";

export const style: { [key: string]: CSS.Properties } = {
    container: {
        width: "100%",
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        padding: "0 5px 0 5px",
        overflow: "hidden",
        height: "calc(75vh - 100px)",
    },
    scrollManager: {
        width: "100%",
        overflowY: "scroll",
        paddingRight: "22px",
        boxSizing: "content-box",
        textAlign: "center",
        // height: "40vh"
        // height: "calc(100vh - 234px)",
    }
}

