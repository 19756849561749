import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
    },
    content: {
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    scrollManager: {
        height: "100%",
        width: "100%",
        overflow: "auto",
        paddingRight: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "700",
        // marginLeft: "20px",
        marginBottom:0
    },
    selectable: {
        paddingLeft: "32px",
        paddingRight: "32px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    checkbox: {
        width: "1.5em",
        height: "1.5em",
        backgroundColor: "white",
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        cursor: "pointer",
        margin: 0
    },
    checkboxSelected: {
        width: "1.5em",
        height: "1.5em",
        backgroundColor: colors.primaryLight,
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        margin: 0,
        cursor: "pointer",
    },
    centeredRow: {
        alignItems: "center",
        marginBottom: "8px",
        flexWrap:"unset",
        marginLeft:0
    },
    text: {
        marginLeft: "15px",
        fontWeight: 400,
        fontSize: "16px",
    },
    filterContent: {
        padding: "0px 32px 0 32px"
    },
}