import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: CSS.Properties = {
    height: "46px",
    backgroundColor: colors.supportWhite,
    border: `1px solid ${colors.supportLight}`,
    boxShadow: "0px 2px 1px 0px #1018280D",
    borderRadius: "8px",
    fontSize: "16px",
    color: colors.supportBlack,
    fontWeight: 600,
    lineHeight: "21.79px",
    width: "100%",
    fontFamily: "'Literata', serif"
}