import React, {useEffect, useState} from "react";
import {ContentsResult, PostsResponse} from "../type";
import TokenManager from "../../../../common/TokenManager";
import {FilterConverter} from "../../FilterByNew/FilterManager";
import Toast, {ApiError} from "../../../../common/Toast";
import {increment} from "../../../../common/Redux/reducers/queryCounter";
import {useNavigate} from "react-router-dom";
import {Filters} from "../../FilterByNew/types";
import {useAppDispatch} from "../../../../common/Redux/hooks";

export default function useGetContents(pageNumber: number,
                                       filters: Filters,
                                       setRequestId: React.Dispatch<React.SetStateAction<string>>,
                                       active: boolean
) {
    const [loading, setLoading] = useState<boolean>(false)
    const [contents, setContents] = useState<ContentsResult[]>([])
    const [hasMore, setHasMore] = useState<boolean>(false)
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    useEffect(() => {
        setContents([])
    }, [active, filters])

    useEffect(() => {
        if (!loading && active) {
            setLoading(true)
            getContent()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, active, filters])

    async function getContent() {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let params = FilterConverter(filters)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/discovery/contents/?offset=${pageNumber * 50}&resultNumber=50${params === "" ? "" : `&${params}`}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!fetched.ok) {
            setLoading(false)
            let data: ApiError = await fetched.json()
            if(data.message!=="Total number of results achieved")
                Toast(data)
            if (data.message === "Endpoint request timed out") {
                Toast(data)
                setContents([])
                setHasMore(false)
            }
            return
        }
        dispatch(increment())
        const data: (PostsResponse & { results: ContentsResult[] }) = await fetched.json()
        setRequestId(data["processing-data"].requestid)
        setContents(prevContents => [...prevContents, ...data.results])
        setHasMore(data.results.length === 50)
        setLoading(false)
    }

    return {loading, hasMore, contents}
}