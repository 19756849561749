import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    input: {
        color: colors.supportBlack,
        border: `solid 1px ${colors.supportLight}`
    }
}