import React, {Dispatch, useEffect} from "react";
import {style} from "./style";
import {Col, Row} from "react-bootstrap";
import CheckboxFilter from "../../../../CheckboxFilter/CheckboxFilter";
import {FilterContent} from "../../FilterByNew";

type Props = {
    selectable: FilterContent[],
    selected: FilterContent[],
    setSelected: (filterName: string, selectedFilters: FilterContent[]) => void
    setIsLocale: Dispatch<boolean>
}

export default function GeoFilter({selectable, setSelected, selected, setIsLocale}: Props) {
    const [macroCategory, setMacroCategory] = React.useState<boolean[]>([false, false])

    useEffect(() => {
        if (selected.length > 0) {
            if (selected[0].name === "Nazionale")
                setMacroCategory([true, false])
            else {
                setMacroCategory([false, true])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectMacro = (select: "Nazionale" | "Locale") => {
        if (select === "Nazionale") {
            if (!macroCategory[0]) {
                setMacroCategory([true, false])
                let nationalIndex = selectable.findIndex((element) => element.name === "Nazionale")
                if (nationalIndex !== -1)
                    setSelected("geoCategories", [selectable[nationalIndex]])
            } else {
                setMacroCategory([false, false])
            }
            setIsLocale(false)
        } else {
            if (!macroCategory[1]) {
                setMacroCategory([false, true])
                setIsLocale(true)
                setSelected("geoCategories", [])
            } else {
                setIsLocale(false)
                setMacroCategory([false, false])
            }
        }
    }
    const selectLocal = (add: FilterContent) => {
        let index = selected.findIndex((element) => element.subname === add.subname)
        if (index === -1)
            setSelected("geoCategories", [...selected, add])
        else {
            const selectedCopy = [...selected]
            selectedCopy.splice(index, 1)
            setSelected("geoCategories", selectedCopy)
        }
    }

    return (<>
            <div style={style.header}>
                <h3 style={style.title}>{"Copertura geografica"}</h3>
            </div>
            <div style={style.filterContent}>
                <Row style={style.centeredRow}>
                    <input type={"checkbox"}
                           style={macroCategory[0] ? style.checkboxSelected : style.checkbox}
                           onClick={() => selectMacro("Nazionale")}/>
                    <span style={style.text}>{"Nazionale"}</span>
                </Row>
                <Row style={style.centeredRow}>
                    <input type={"checkbox"}
                           style={macroCategory[1] ? style.checkboxSelected : style.checkbox}
                           onClick={() => selectMacro("Locale")}/>
                    <span style={style.text}>{"Locale"}</span>
                </Row>
                {macroCategory[1] ?
                    <Row style={{marginTop: "20px"}}>
                        <Col>
                            {/*<h3 style={{marginLeft: "20px", fontSize:"18px"}}>Seleziona regione</h3>*/}
                            {selectable.map((element, index) => {
                                if (element.name === "Locale" && element.subname !== undefined)
                                    return <Row key={`geoFilter-${index}`}>
                                        <CheckboxFilter category={element.subname}
                                                        checked={selected.findIndex((search) => search.subname === element.subname) > -1}
                                                        handleOnChange={() => selectLocal(element)}/>
                                    </Row>
                                return null
                            })}
                        </Col>
                    </Row>
                    : null}
            </div>
        </>
    );
}