import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: CSS.Properties = {
    fontWeight: 400,
    color: colors.supportBlack,
    fontSize: "18px",
    lineHeight: "27px",
    width: "100%",
    margin: 0,
}