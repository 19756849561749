import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    outerBox: {
        width: "178px",
        marginTop:"10px"
    },
    box: {
        padding: "3px 8px 3px 8px",
        width: "35px",
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.primaryLight,
        borderRadius: "4px",
        border: "none",
        transition: "width 0.5s",
        overflow: "hidden",
        cursor:"pointer"
    },
    boxHovered: {
        padding: "3px 8px 3px 8px",
        width: "178px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        backgroundColor: colors.primaryLight,
        borderRadius: "4px",
        border: "none",
        transition: "width 0.5s",
        overflow: "hidden",
        cursor:"pointer"
    },
    icon: {
        height: "15px",
        width: "auto"
    },
    text: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        marginRight: "12px",
        whiteSpace: "nowrap",
        width:0,
        overflow:"hidden"
    },
    textHovered: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        marginLeft: "12px",
        whiteSpace: "nowrap",
        width:"180px",
        overflow:"hidden"
    }
}