import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
        paddingBottom: "24px"
    },
    content: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    filters: {
        width:"100%",
        overflowY: "scroll",
        paddingRight: "17px", /* Increase/decrease this value for cross-browser compatibility */
        boxSizing: "content-box",
        paddingBottom: "40px",
        flex: "1"
    },
    title: {
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: 0
    },
    filter: {
        cursor: "pointer",
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: "8px",
        border: `1px solid ${colors.supportLight}`,
        borderRadius: "8px",
        // paddingRight: "16px",
        fontSize: "16px",
        fontWeight: "400",
        color: colors.supportBlack,
        boxShadow: "0px 2px 1px 0px #1018280D",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}