import React from "react";
import {style} from "./style";
import LoginPointOutTitle from "../components/LoginPointOutTitle/LoginPointOutTitle";
import LoginTitle from "../components/LoginTitle/LoginTitle";
import LoginLabel from "../components/LoginLabel/LoginLabel";
import LoginInput from "../components/LoginInput/LoginInput";
import LoginButton from "../components/LoginButton/LoginButton";
import LoginSecondaryButton from "../components/LoginSecondaryButton/LoginSecondaryButton";
import LoginSideSlide from "../components/LoginSideSlide/LoginSideSlide";
import LoginSubtitle from "../components/LoginSubtitle/LoginSubtitle";
import Toast, {ApiError} from "../../../common/Toast";
import {useNavigate} from "react-router-dom";

export default function ForgotPassword() {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>("")
    const navigate = useNavigate();

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const sendEmail = async () => {
        setLoading(true)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/password/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email
            })
        })
        setLoading(false)
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        navigate("/login")
    }

    const emailValidation = () => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    }

    return (
        <div style={style.page}>
            <div style={style.column}>
                <div style={style.content}>
                    <div style={style.logo}>
                        <LoginPointOutTitle/>
                    </div>
                    <iframe title={"ForgotPassword"} name="votar" style={{display: "none"}}></iframe>
                    <form action="about:blank" onSubmit={sendEmail} target="votar">
                        <LoginTitle className={"mb32"} text={"Hai dimenticato la password?"}/>
                        <LoginSubtitle className={"mb32"}
                                       text={"Non ti preoccupare. Inserisci il tuo indirizzo mail e riceverai un link per cambiare la password."}/>
                        <LoginLabel text={"E-mail"}/>
                        <LoginInput className={"mb32"} type={"email"} placeholder={"Inserisci la tua mail"}
                                    setValue={onEmailChange}/>
                        <LoginButton className={"mb16"} text={"Invia il link di ripristino della password"}
                                     onClick={() => console.log("clicked")} loading={loading}
                                     disabled={!emailValidation()}/>
                        <LoginSecondaryButton text={"Ricordo la mia password"}
                                              onClick={() => navigate("/login")}/>
                    </form>
                </div>
            </div>
            <div style={style.column}>
                <LoginSideSlide title={"Lo sapevi?"}
                                text={"Copriamo più di 1.500 testate. Analizziamo i contenuti relativi a tutti i settori dell'economia, dall'ICT ai settori più analogici"}/>
            </div>
        </div>
    );
}