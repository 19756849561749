import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    container: {
        padding: "102px 32px 32px 32px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    title: {
        fontWeight: "700",
        fontSize: "32px",
        color: colors.supportBlack,
        margin: 0,
        marginBottom: "48px"
    },
    send: {
        border: "none",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: colors.primaryLight,
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack
    },
    sendDisabled: {
        border: "none",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: colors.primaryLightOpacity,
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportDark
    },
    subtitle: {
        fontWeight: "700",
        fontSize: "24px",
        color: colors.supportBlack,
        margin: 0,
        marginBottom: "16px",
        width: "100%",
    },
    label: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        width: "100%",
        marginBottom: "4px"
    },
    input: {
        outline: "none",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 2px 1px 0px #1018280D",
        width: "100%",
        marginBottom: "16px"
    },
    column: {
        maxWidth: "600px",
        marginBottom: "48px",
        width: "100%"
    },
    video:{
        width:"100%"
    }
}