import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        width: "100%",
        height: "calc(100% - 80px)",
        marginTop: "80px",
        position: "fixed",
        top: 0,
        backgroundColor: "rgba(29,29,27,0.3)",
        zIndex: 10000,

    },
    box2: {
        // width: "100%",
        height: "calc(100% - 80px)",
        marginTop: "80px",
        position: "fixed",
        top: 0,
        zIndex: 10000,
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        boxShadow: "0px 10px 61px 22px #0000001f"
    },
    modal: {
        height: "100%",
        marginLeft: "10%",
        padding: "40px 30px 40px 38px",
        backgroundColor: colors.supportWhite,
        overflowY: "scroll",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    modal2: {
        height: "100%",
        padding: "40px 30px 40px 38px",
        backgroundColor: colors.supportWhite,
        overflowY: "scroll",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "32px"
    },
    button: {
        height: "46px",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `1px solid ${colors.supportLight}`,
        backgroundColor: "white",
        boxShadow: "0px 2px 1px 0px #1018280D",
        display: "flex",
        alignItems: "center"
    },
    footer: {
        fontSize: "16px",
        fontWeight: 400,
        margin: 0,
    },
    bold: {
        fontSize: "16px",
        fontWeight: 600,
        margin: 0,
    }
}