import CSS from 'csstype';
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    active: {
        backgroundColor: colors.primaryLight,
        height: " 46px",
        width: "100%",
        padding: "12px, 16px, 12px, 16px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportBlack,
        border: "none",
        boxShadow: "0px 2px 1px 0px #1018280D",
        cursor: "pointer",
        fontFamily: "'Literata', serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    disabled: {
        backgroundColor: colors.primaryLightOpacity,
        height: " 46px",
        width: "100%",
        padding: "12px, 16px, 12px, 16px",
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportLight,
        border: "none",
        boxShadow: "0px 2px 1px 0px #1018280D",
        cursor: "pointer",
        fontFamily: "'Literata', serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

}