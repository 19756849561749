import {Datapoint} from "../../MediaAnalytics";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer,} from "recharts";
import React, {useEffect} from "react";
import {colors} from "../../../../../common/colors";

type Props = {
    datapoints: Datapoint[]
}

type Data = {
    name: string,
    value: number
}[]

const donutColors = ["#ee9a3a", colors.primaryLight, colors.primaryLightOpacity]

export default function Donut({datapoints}: Props) {

    const [data, setData] = React.useState<Data>([])

    useEffect(() => {
        const newData = []
        for (const datapoint of datapoints) {
            newData.push({
                name: datapoint["series"],
                value: datapoint["y-value"]
            })
        }
        setData(newData)
    }, [datapoints])

    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}: any) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} fontSize={"16px"}
                  dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="90%">
            <PieChart width={300} height={300}>
                <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={50}
                     outerRadius={110} labelLine={false}
                     label={renderCustomizedLabel}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={donutColors[index]}/>
                    ))}
                </Pie>
                <Legend formatter={(value, entry, index) => <span style={{color: "#000000"}}>{value}</span>}/>
            </PieChart>
        </ResponsiveContainer>
    )
}