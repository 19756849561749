import {style} from "./style";
import React from "react";

type Props = {
    active: boolean,
    onStop: () => void
}

export default function FilterBySecondTab({active, children, onStop}: React.PropsWithChildren<Props>) {
    return (
        <div style={active ? style.boxAnimate : style.box}>
            <div style={active? style.box2Active : style.box}>
                <div style={style.content}>
                    {children}
                </div>
                <div onClick={onStop} style={{width: "calc(100% - 375px)"}}/>
            </div>
        </div>
    )
}