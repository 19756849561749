import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Header from "./header/Header";

export default function Home() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/")
            navigate("/discovery")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", height: "111vh"}}>
            <Header/>
            <Outlet/>
        </div>
    );
}