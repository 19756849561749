import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        // paddingTop: "80px",
        // height: "100vh",
        width: "100%",
        flex: "1",
        display: "flex",
        flexDirection: "row",
        overflowY: "scroll"
    },
    content: {
        padding: "32px",
        width: "calc(100% - 400px)",
        marginLeft: "400px"
    },
    contentNoScroll: {
        padding: "32px",
        // width:"100%",
        width: "calc(100% - 400px)",
        marginLeft: "400px",
        overflow: "hidden"
    },
    title: {
        fontWeight: "700",
        fontSize: "32px",
        margin: 0,
        marginBottom: "8px"
    },
    subtitle: {
        fontWeight: "400",
        fontSize: "16px",
        margin: 0,
        marginBottom: "32px"
    },
    tabList: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
        margin: 0
    },
    download: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.primaryLight,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: "none",
        lineHeight: "21.79px"
    },
    downloadDisabled: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.primaryLightOpacity,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: "none",
        lineHeight: "21.79px"
    }
}