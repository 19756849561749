import React from "react";
import {style} from "./style";
import PostResults, {PostResult} from "./Results/PostResults";
import NewPost from "./NewPost/NewPost";

type Props = {
    visible: boolean,
    postResult: PostResult | undefined,
    setVisible: () => void
    setRefresh: () => void
}

export default function ViewPost({setVisible, postResult, visible, setRefresh}: Props) {
    const onClose = () => {
        setVisible()
    }

    if (!visible)
        return null
    return (
        <div style={style.box}>
            <div style={style.modal}>
                {postResult !== undefined ? <PostResults onClose={onClose} result={postResult} setRefresh={setRefresh}/>
                    : <NewPost close={onClose} setRefresh={setRefresh}/>}
            </div>
        </div>);
}

