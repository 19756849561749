import CSS from "csstype";

export const style: { [key: string]: CSS.Properties } = {
    page: {
        height: "111vh",
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    column: {
        width: "50%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    content: {
        position: "relative",
        maxWidth: "497px",
        padding: "40px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        flexDirection:"column"
    },
    logo: {
        // position: "absolute",
        // left: "40px",
        // top:"133px"
        height:"25vh",
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"left"
    },
}