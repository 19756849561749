import React from "react";
import AuthCode from "react-auth-code-input";
import {style} from "./style";
import "./style.css";

type Props = {
    setCode: (code: string) => void,
    className?: string
}

export default class LoginTwoFactorInput extends React.Component<Props, any> {

    handleOnChange = (code: string) => {
        this.props.setCode(code)
    };

    render() {
        return (
            <div className={this.props.className} style={style.box}>
                <AuthCode inputClassName={"twoFactorInput"} allowedCharacters='numeric' onChange={this.handleOnChange}/>
            </div>
        )
    }
}

