import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        borderRadius: "16px",
        border: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 3px 10px 0px #3636361C",
        padding: "16px 24px 16px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: "16px"
    },
    nameImage: {
        backgroundColor: colors.primaryLight,
        borderRadius: "20px",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "29.7px"
    },
    resultTitle: {
        margin: 0,
        fontWeight: 700,
        fontSize: "20px",
        marginTop: "-6px",
        marginRight: "8px",
        color: colors.supportBlack,
        // whiteSpace: "nowrap",
    },
    resultRole: {
        margin: 0,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19.07px",
        color: colors.supportDark
    },
    resultSubtitle: {
        margin: 0,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19.07px",
        color: colors.supportDarker,
        marginTop: "4px"
    },
    resultInfoTextLeft: {
        marginTop: "21px",
        marginLeft: "40px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19.07px"
    },
    resultInfoTextRight: {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "21.79px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    img: {
        width: "18px",
        height: "auto",
        marginRight: "13px",
        // marginLeft: "13px"
    },
    text: {
        fontWeight: 400,
        fontSize: "14px",
        color: colors.supportDarker
    },
    textCenterVertically: {
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    flex: {
        display: "flex",
    }
}