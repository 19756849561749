import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
    },
    content: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    },
    scrollManager: {
        height: "calc(100% - 192px)",
        width: "calc(100% - 32px)",
        overflow: "auto",
        boxSizing: "unset",
        paddingRight: "20px",
        paddingLeft: "32px",
        paddingBottom: "40px"
    },
    title: {
        fontSize: "24px",
        fontWeight: "700",
        marginBottom: 0
    },
    treeRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "4px",
        userSelect: "none",
        justifyContent: "space-between",
        paddingRight: "16px"
    },
    iconBox: {
        width: "15px"
    },
    iconRight: {
        marginTop: "5px",
        textAlign: "left",
        width: "7px"
    },
    iconDown: {
        marginTop: "7px",
        textAlign: "left",
        width: "10px"
    },
    text: {
        fontWeight: 600,
        marginBottom: "4px",
        userSelect: "none",
    },
    nestedRow: {
        marginLeft: "15px",
        // marginBottom: "10px",
    },
    leaf: {
        marginLeft: "15px",
        marginBottom: "4px",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
    },
    input: {
        height: "46px",
        width: "100%",
        outline: "none",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        padding: "12px 16px 12px 16px",
        marginBottom: "8px"
    },
    inputBox: {
        paddingRight: "32px",
        paddingLeft: "32px",
        paddingBottom: "16px"
    },
    searchResults: {
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        marginBottom: "16px",
        height: "calc(100% - 192px)",
        display: "flex",
        flexDirection: "column",
        marginLeft: "32px",
        marginRight: "32px",
        overflow: "hidden"
    },
    searchResultsTitle: {
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "16px",
        letterSpacing: "0em",
        textAlign: "left",
        margin: 0,
        color: colors.supportDark,
        marginBottom: "8px"
    },
    selected: {
        padding: "8px 12px",
        borderRadius: "8px",
        backgroundColor: colors.supportLighter,
        color: colors.supportBlack,
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "8px",
        cursor: "pointer",
        display: "inline-flex",
        marginRight: "12px",
        alignItems: "center"
    },
    plus: {
        paddingRight: "16px"
    }

}