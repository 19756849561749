import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        // paddingTop: "80px",
        // height: "100vh",
        width: "100%",
        display: "flex",
        flex: "1",
        overflowY: "scroll",
        flexDirection: "row",
    },
    content: {
        padding: "32px 8px 32px 432px",
        width: "100%",
    },
    subtitle: {
        fontWeight: "400",
        fontSize: "16px",
        margin: 0,
        marginBottom: "32px"
    },
    analyticsBoxTitle: {
        fontSize: "20px",
        fontWeight: 700,
        margin: 0,
        marginBottom: "16px"
    },
    analyticsBox: {
        height: "50vh",
        paddingBottom: "24px",
    },
    analyticsBoxContent: {
        height: "100%",
        width: "100%",
        borderRadius: "16px",
        padding: "24px",
        border: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 3px 10px 0px #3636361C",
        // marginRight: "24px",
        marginBottom: "24px"
    }
}