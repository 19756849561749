import React from "react";
import {Row} from "react-bootstrap";
import {style} from "../style";

type Props = {
    onTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    title: string
}
export default class PostTitle extends React.Component<Props, any> {
    render() {
        return (
            <div  style={{marginRight:"5px"}}>
                <Row className={"m-0 w-100"} style={{textAlign:"start"}}>
                    <h3 style={style.postTitle}>{"Titolo"}</h3>
                </Row>
                <Row className={"m-0 w-100"}>
                    <input style={style.titleInput} value={this.props.title} onChange={this.props.onTitleChange}
                           type={"text"} placeholder={"Inserisci il titolo"}/>
                </Row>
            </div>
        );
    }
}