import CSS from "csstype";
import {colors} from "../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        padding: "24px",
        borderRadius: "16px",
        backgroundColor: colors.supportLighter,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 3px 10px 0px #3636361C",
        border: `1px solid ${colors.supportLight}`,
        flexDirection: "column",
        marginBottom:"16px"
    },
    mainText: {
        fontSize: "16px",
        fontWeight: "600",
        color: colors.supportBlack,
        maxWidth:"600px",
        textAlign:"center",
    },
    secondaryText: {
        fontSize: "14px",
        fontWeight: "400",
        color: colors.supportBlack,
        fontStyle: "italic",
        textAlign:"center",
        maxWidth:"500px"
    }
}