import React, {useEffect, useState} from "react";
import {style} from "./style";
import LoginPointOutTitle from "../components/LoginPointOutTitle/LoginPointOutTitle";
import LoginTitle from "../components/LoginTitle/LoginTitle";
import LoginButton from "../components/LoginButton/LoginButton";
import LoginSideSlide from "../components/LoginSideSlide/LoginSideSlide";
import LoginLabel from "../components/LoginLabel/LoginLabel";
import LoginInput from "../components/LoginInput/LoginInput";
import LoginPasswordRequirements from "../components/LoginPassworRequirement/LoginPasswordRequirements";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {colors} from "../../../common/colors";
import TokenManager from "../../../common/TokenManager";
import Toast, {ApiError} from "../../../common/Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../common/Loader/Loader";

export default function ChangePassword() {
    const [ready, setReady] = React.useState<boolean>(false)
    const [password, setPassword] = useState<string>("")
    const [passwordConfirm, setPasswordConfirm] = useState<string>("")
    const [eightChar, setEightChar] = useState<boolean>(false)
    const [upperCase, setUpperCase] = useState<boolean>(false)
    const [specialChar, setSpecialChar] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    // const [error, setError] = useState<boolean>(false)
    const [errorPasswordMatch, setErrorPasswordMatch] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")
    const {state}: { state: { password_change_needed: boolean } } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false)

    useEffect(() => {
        if (state?.password_change_needed === false) {
            TokenManager.login().then(() => {
                navigate("/discovery")
                return
            })
        } else {
            setReady(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // @ts-ignore
        for (const [key, value] of searchParams.entries()) {
            if (key === "token") {
                setToken(value)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const managePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        let specialRegExp = new RegExp("[$&+,:;=?@#|'<>.^*()%!-]")
        let longRegExp = new RegExp("[A-Za-z\\d!@#$%^&*()_+]{8,20}")
        let upperRegExp = new RegExp("(?=.*[A-Z])")
        let value = e.target.value
        if (specialRegExp.test(value))
            setSpecialChar(true)
        else
            setSpecialChar(false)
        if (longRegExp.test(value))
            setEightChar(true)
        else
            setEightChar(false)
        if (upperRegExp.test(value))
            setUpperCase(true)
        else
            setUpperCase(false)
        setPassword(value)
    }

    const setPasswordConfirmLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(e.target.value)
    }

    const changeManager = () => {
        if (token.length > 0)
            changePassword()
        else
            resetPassword()
    }

    const changePassword = async () => {
        setErrorPasswordMatch(false)
        if (password === passwordConfirm) {
            setLoading(true)
            let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/password/forgot`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: token,
                    password: password
                })
            })
            if (!fetched.ok) {
                setLoading(false)
                let data: ApiError = await fetched.json()
                Toast(data)
                return
            }
            setPasswordChanged(true)
            setTimeout(() => {
                navigate("/login")
            }, 3000);
        } else {
            setErrorPasswordMatch(true)
        }
    }

    const resetPassword = async () => {
        setErrorPasswordMatch(false)
        if (password === passwordConfirm) {
            setLoading(true)
            // setError(false)
            const token_1f = TokenManager.getFactorToken(1)
            const token_2f = TokenManager.getFactorToken(2)
            if (token_1f === null || token_2f === null) {
                navigate("/login")
                return
            }
            let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/password/reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token_1f: token_1f,
                    token_2f: token_2f,
                    new_password: password
                })
            })
            if (!fetched.ok) {
                setLoading(false)
                let data: ApiError = await fetched.json()
                Toast(data)
                return
            }
            let data: { token_1f: string } = await fetched.json()
            TokenManager.setFactorToken(data.token_1f, 1)
            login()
        } else {
            setErrorPasswordMatch(true)
        }
    }

    const login = async () => {
        const tokenLogin = await TokenManager.login()
        if (tokenLogin === null) {
            navigate("/login")
            return
        }
        navigate("/carousel")
    }

    return (
        <div style={style.page}>
            <div style={style.column}>
                {ready ? <div style={style.content}>
                    <div style={style.logo}>
                        <LoginPointOutTitle/>
                    </div>
                    <iframe title={"ChangePassword"} name="votar" style={{display: "none"}}></iframe>
                    <form action="about:blank" onSubmit={changeManager} target="votar">
                        <LoginTitle className={"mb32"} text={"Welcome! Come prima cosa, cambia la tua password"}/>
                        <LoginLabel text={"Password"}/>
                        <LoginInput type={"password"} placeholder={"Inserisci la tua password"}
                                    setValue={managePassword}/>
                        <LoginPasswordRequirements active={eightChar} text={"Lunghezza minima di 8 caratteri"}/>
                        <LoginPasswordRequirements active={upperCase} text={"Almeno un carattere maiuscolo"}/>
                        <LoginPasswordRequirements active={specialChar} className={"mb32"}
                                                   text={"Almeno un carattere speciale"}/>
                        <LoginLabel text={"Ripeti la password"}/>
                        <LoginInput className={!passwordChanged ? "mb32" : "mb16"} type={"password"}
                                    placeholder={"Ripeti la tua password"}
                                    setValue={setPasswordConfirmLocal}/>
                        {!passwordChanged ? <LoginButton className={"mb16"} text={"Modifica e accedi"}
                                                         loading={loading}
                                                         disabled={!specialChar || !eightChar || !upperCase}/> :
                            <div className={"w-100 d-flex justify-content-center align-items-center"}>
                                <FontAwesomeIcon icon={faCircleCheck} size={"2xl"} color={colors.primaryLight}/>
                            </div>}
                        {errorPasswordMatch ?
                            <p style={{color: colors.supportDanger}}>{"Le password non coincidono"}</p> : null}
                    </form>
                </div> : <Loader/>}
            </div>
            <div style={style.column}>
                <LoginSideSlide title={"Lo sapevi?"}
                                text={"Copriamo più di 1.500 testate. Analizziamo i contenuti relativi a tutti i settori dell'economia, dall'ICT ai settori più analogici"}/>
            </div>
        </div>);
}