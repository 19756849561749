import React, {useEffect, useState} from 'react';
import './App.css';
import {Navigation} from "./common/navigation/navigation";
import logo from "./assets/png/point.png"

function App() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    if (isMobile)
        return <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                textAlign: "center",
                flexDirection: "column"
            }}>
            <img src={logo} style={{width: "80%"}} alt={""}/>
            Il portale al momento non supporta la visualizzazzione da smartphone, prova ad aprirla da browser
        </div>
    return (
        <Navigation/>
    );
}


export default App;