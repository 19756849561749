import React from "react";
import {style} from "./style";
// import ExpandSupport from "./ExpandSupport/ExpandSupport";
// import ReactPlayer from "react-player";

export default function Support() {
    // const [loading, setLoading] = React.useState<boolean>(false)
    // const [subject, setSubject] = React.useState<string>("")
    // const [question, setQuestion] = React.useState<string>("")
    // const [expanded, setExpanded] = React.useState<number>(-1)

    return (
        <div style={style.container}>
            <h3 style={style.title}>Support center</h3>
            <div style={style.column}>
                <h5 style={style.subtitle}>{"Contattaci"}</h5>
                <label style={style.label}>{"Soggetto"}</label>
                <input style={style.input} placeholder={"Inserisci un breve testo"}/>
                <label style={style.label}>{"Domanda"}</label>
                <textarea style={style.input} rows={8} placeholder={"Descrivi la tua domanda"}/>
                <button style={style.send}>{"Invia"}</button>
            </div>
            {/*<div style={style.column}>
                <h5 style={style.subtitle}>{"Domande frequenti"}</h5>
                <input style={style.input} placeholder={"Search"}/>
                <ExpandSupport title={"Video di onboarding"}>
                    <ReactPlayer controls={true} width='100%' url='https://www.youtube.com/watch?v=ysz5S6PUM-U'/>
                </ExpandSupport>
                <ExpandSupport title={"Question"}>
                    <p>Adipiscing at leo neque mattis lacus egestas. Cras turpis eros luctus dignissim arcu risus at
                        enim. Vel vestibulum non nullam senectus tellus duis vitae sed. Sed et commodo amet tortor morbi
                        eu quam sagittis. Gravida venenatis tellus nisl tellus sit enim. Sapien egestas donec facilisi
                        </p>
                </ExpandSupport>
            </div>*/}
        </div>
    )
}