import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {style} from "./style";
import pc from "../../../../../assets/png/pc.png"
import newspaper from "../../../../../assets/png/newspaper.png"
import position from "../../../../../assets/png/position.png"
import infoMan from "../../../../../assets/png/infoMan.png"
import stats from "../../../../../assets/png/stats.png"
import starMan from "../../../../../assets/png/starMan.png"
import linkedin from "../../../../../assets/png/linkedin.png"
import twitter from "../../../../../assets/png/twitter.png"
import phone from "../../../../../assets/png/phone.png"
import mail from "../../../../../assets/png/mail.png"
import {SourcesResults} from "../../type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../../../common/colors";
import AddToFilters from "../AddToFilters/AddToFilters";
import numberWithPoints from "../../../../../common/numberWithDots";
import stonks from "../../../../../assets/png/stonks.png";
import * as CSV from "csv/browser/esm";


type Props = {
    result: SourcesResults,
    filterClick?: () => void
}

const staffParse: { [key: string]: string } = {
    full_name: "Nome",
    role: "Ruolo",
    services: "Servizi",
    email: "Email",
    notes: "Note"
}

export default function TitlesResults({result, filterClick}: Props) {
    const [expanded, setExpanded] = useState(false)
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const assembleCsv = (result: SourcesResults): string[][] => {
        let csv = [Object.values(staffParse)]
        if(result.staff!== null && result.staff.length > 0) {
            for (const staff of result.staff) {
                let staffArray: string[] = []
                for (const key of Object.keys(staffParse)) {
                    // @ts-ignore
                    staffArray.push(staff[key])
                }
                csv.push(staffArray)
            }
        }
        return csv
    }

    const staffCsv = assembleCsv(result)

    const create = () => {
        CSV.stringify(staffCsv, {
            delimiter: ';',
            bom: true
        }, (err, output) => {
            downloadFile(output, `${result.source.name}-staff`)
        });
    }

    const downloadFile = (data: string, fileName: string) => {
        const blob = new Blob([data], {type: "text/csv"})
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const lastColumn = (<React.Fragment>
        {result.emails !== null && result.emails.length > 0 ?
            result.emails.map((email, index) => {
                return (
                    <Row style={style.infoRow} key={`email-${email}-${index}`}>
                        <div style={style.imageContainer}>
                            <img src={mail} alt={""} style={style.rightImage}/>
                        </div>
                        <a style={style.rightInfoText}
                           href={`mailto:${email}`} target="_blank"
                           rel={"noreferrer"}>{email}</a>
                    </Row>)
            }) : null}
        {result.linkedin.name !== null ? <Row style={style.infoRow}>
            <div style={style.imageContainer}>
                <img src={linkedin} alt={""} style={style.rightImage}/>
            </div>
            <a style={style.rightInfoText} href={result.linkedin.url}
               target="_blank" rel={"noreferrer"}>{result.linkedin.name}</a>
        </Row> : null}
        {result.twitter.name !== null ? <Row style={style.infoRow}>
            <div style={style.imageContainer}>
                <img src={twitter} alt={""} style={style.rightImage}/>
            </div>
            <a style={style.rightInfoText} href={result.twitter.url}
               target="_blank" rel={"noreferrer"}>{result.twitter.name}</a>
        </Row> : null}
        {result.phones !== null && result.phones.length > 0 ?
            result.phones.map((phoneNumber, index) => {
                return (
                    <Row style={style.infoRow} key={`phone-${phoneNumber}-${index}`}>
                        <div style={style.imageContainer}>
                            <img src={phone} alt={""} style={{height: "18px", width: "auto"}}/>
                        </div>
                        <a style={style.rightInfoText} href={`tel:${phoneNumber}`}
                           target="_blank" rel={"noreferrer"}>{phoneNumber}</a>
                    </Row>)
            }) : null}
        {result.staff !== null && result.staff.length > 0 ?
            <Row style={style.redazioneButton}>
                <div style={style.download} onClick={() => setExpanded(!expanded)}>
                    {expanded ? "Riduci" : "Espandi redazione"}
                </div>
            </Row> : null}
    </React.Fragment>)

    return (
        <div style={style.mb24}>
            <div style={!expanded ? style.box : style.boxNoBBorderRadius}>
                <Row style={{justifyContent: "space-between"}}>
                    <Col style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <Row>
                            <Col className={"d-flex flex-column"} xl={12} xxl={6}>
                                <div style={style.header}>
                                    <div style={style.icon}>
                                        {result.mediaType.name === "Stampa digitale" ? <img src={pc} alt={""}/>
                                            : <img src={newspaper} alt={""}/>}
                                    </div>
                                    <div className={"d-flex flex-column"} style={{width: "calc(100% - 50px)"}}>
                                        <a rel={"noreferrer"} style={style.title}
                                           href={result.website ? result.website : undefined}
                                           target="_blank">{result.source.name}</a>
                                        {filterClick ? <AddToFilters onClick={filterClick}/>
                                            : <div className={"mt-2"}>
                                                <Row style={style.leftRow}>
                                                    <FontAwesomeIcon icon={faMapLocationDot}
                                                                     color={colors.supportDarker}
                                                                     style={style.image}/>
                                                    <span
                                                        style={style.infoText}>{result.geoCategory.name}{result.geoCategory.name === "Locale" ? `, ${result.geoCategory.subname}` : ""}</span>
                                                </Row>
                                                <Row style={style.leftRow} className={"mt-1"}>
                                                    <img src={infoMan} alt={""} style={style.image}/>
                                                    <span style={style.infoText}>{result.sourceProfile.name}</span>
                                                </Row>
                                            </div>}
                                    </div>
                                </div>
                                {filterClick ? <>
                                    <Row style={style.leftRow}>
                                        <FontAwesomeIcon icon={faMapLocationDot} color={colors.supportDarker}
                                                         style={style.imageLeft}/>
                                        <span
                                            style={style.infoText}>{result.geoCategory.name}{result.geoCategory.name === "Locale" ? `, ${result.geoCategory.subname}` : ""}</span>
                                    </Row>
                                    <Row style={style.leftRow} className={"mt-1"}>
                                        <img src={infoMan} alt={""} style={style.imageLeft}/>
                                        <span style={style.infoText}>{result.sourceProfile.name}</span>
                                    </Row>
                                </> : null}
                            </Col>
                            <Col xl={12} xxl={6}>
                                <div className={"ml-xl-50"}>
                                    {result.publications !== null ? <Row style={style.leftRow}>
                                        <img style={style.stonks} src={stonks} alt={""}/>
                                        <span style={style.infoText}>
                                        {`${numberWithPoints(result.publications.number)} articoli pubblicati negli ultimi sei mesi`}
                                    </span>
                                    </Row> : null}
                                    <Row style={style.leftRow} className={"mt-1"}>
                                        <img style={style.image} src={stats} alt={""}/>
                                        <span style={style.infoText}>
                                        {`${numberWithPoints(result.publicationsInSelection)} articoli rilevanti per la ricerca corrente`}
                                    </span>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{height: "100%"}}>
                            <Col xl={12} xxl={6} style={style.lastColumn}>
                                {result.referencePerson !== null ? <Row style={style.infoRow}>
                                    <div style={style.imageContainer}>
                                        <img src={starMan} alt={""} style={style.rightImage}/>
                                    </div>
                                    <span style={style.rightInfoText}>{result.referencePerson}</span>
                                </Row> : null}
                                {result.address !== null ? <Row style={style.infoRow}>
                                    <div style={style.imageContainer}>
                                        <img src={position} alt={""} style={style.rightImage}/>
                                    </div>
                                    <Col style={{flexDirection: "column"}}>
                                        <p style={style.rightInfoText}>{result.address}</p>
                                    </Col>
                                </Row> : null}
                                {windowSize[0] < 1400 ? lastColumn : null}
                            </Col>
                            {windowSize[0] > 1400 ? <Col xl={12} xxl={6} style={style.lastColumn}>
                                {lastColumn}
                            </Col> : null}
                        </Row>
                    </Col>
                </Row>
            </div>
            {/*{expanded && result.staff && result.staff.length > 0 ?*/}
            {result.staff && result.staff.length > 0 ?
                <div style={expanded && result.staff && result.staff.length > 0 ?style.boxRedazioneT:style.boxRedazione}>
                    <div style={{width: "100%", overflowX: "auto", overflowY: "auto", maxHeight: "300px"}}>
                        <table style={style.table}>
                            <thead style={{backgroundColor: "yellow"}}>
                            <tr style={style.tdth}>
                                {Object.keys(staffParse).map((key, index) => {
                                    return (
                                        <th key={`staff-${key}-${index}`} style={style.thead}>
                                            {staffParse[key]}
                                        </th>)
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {result.staff.map((staff, index) => {
                                return (
                                    <tr key={`staff-${index}`}>
                                        {Object.keys(staffParse).map((key, index) => {
                                            return (
                                                <th key={`staff-${key}-${index}`} style={style.trow}>
                                                    {/*@ts-ignore*/}
                                                    {staff[key]}
                                                </th>)
                                        })}
                                    </tr>)
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div style={style.downloadButton}>
                        <div style={style.download} onClick={create}>
                            {"Download CSV"}
                        </div>
                    </div>
                </div> : null}
        </div>
    );
}