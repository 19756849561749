import React, {useEffect} from "react";
import {Carousel} from "react-responsive-carousel";
import {style} from "./style";
import PostTitle from "./postParts/PostTitle";
import PostBody from "./postParts/PostBody";
import PostFilters from "./postParts/PostFilters";
import TokenManager from "../../../../../common/TokenManager";
import {ProcessingData} from "../../../discovery/type";
import CarouselIndicator from "../../../../CarouselIndicator/CarouselIndicator";
import PostResults from "../Results/PostResults";
import {colors} from "../../../../../common/colors";
import {Oval} from "react-loader-spinner";
import Toast, {ApiError} from "../../../../../common/Toast";
import {useNavigate} from "react-router-dom";
import {FilterContent} from "../../../FilterByNew/FilterByNew";

type Props = {
    close: () => void,
    titleEdit?: string,
    bodyEdit?: string,
    filtersEdit?: {
        mediaTypes: FilterContent[],
        geoCategories: FilterContent[],
        sourceProfiles: FilterContent[]
    },
    documentIdEdit?: string,
    setRefresh: () => void
}

export default function NewPost({close, titleEdit, bodyEdit, filtersEdit, documentIdEdit, setRefresh}: Props) {
    let containerTitles = ["Nuova comunicazione", "Corpo dell'articolo", "Filtri", "Risultati"]
    const [title, setTitle] = React.useState<string>(titleEdit ? titleEdit : "")
    const [content, setContent] = React.useState<string>(bodyEdit ? bodyEdit : "")
    const [documentId, setDocumentId] = React.useState<string>(documentIdEdit ? documentIdEdit : "")
    const [file, setFile] = React.useState<undefined | File>(undefined)
    const [mediaFilter, setMediaFilter] = React.useState<FilterContent[]>(filtersEdit ? filtersEdit.mediaTypes : [])
    const [titleFilter, setTitleFilter] = React.useState<FilterContent[]>(filtersEdit ? filtersEdit.sourceProfiles : [])
    const [geoFilter, setGeoFilter] = React.useState<FilterContent[]>(filtersEdit ? filtersEdit.geoCategories : [])
    const [localGeoFilter, setLocalGeoFilter] = React.useState<FilterContent[]>([])
    const [isLocal, setIsLocal] = React.useState<boolean>(false)
    const [nextAvailable, setNextAvailable] = React.useState<boolean>(false)
    const [index, setIndex] = React.useState<number>(0)
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate();
    const manageNext = async () => {
        if (index === 2)
            setNextAvailable(true)
        else
            setNextAvailable(false)
    }

    const savePost = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        const formData = new FormData();
        formData.append("title", title);
        if (file !== undefined)
            formData.append("document", file);
        else
            formData.append("plainText", content);
        if (documentIdEdit !== undefined)
            formData.append("documentId", documentIdEdit)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/smart-targeting/document`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            },
            body: formData
        })
        if (!fetched.ok) {
            setLoading(false)
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        const data: ({
            "processing-data": ProcessingData, results: {
                documentId: string,
                title: string,
                body: string
            }
        }) = await fetched.json()
        setContent(data.results.body)
        setDocumentId(data.results.documentId)
        await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                key: `post-${data.results.documentId}`,
                value: {documentId: data.results.documentId},
                tag: "post"
            })
        })
        await savePostFilters(data.results.documentId)
        setRefresh()
        setIndex(index + 1)
        setLoading(false)
    }

    const savePostFilters = async (documentId: string) => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                key: `post-filters-${documentId}`,
                value: {
                    documentId: documentId,
                    mediaTypes: mediaFilter,
                    geoCategories: geoFilter.length === 0 && isLocal ? localGeoFilter : geoFilter,
                    sourceProfiles: titleFilter
                },
                tag: "post-filters"
            })
        })
    }

    const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }

    const onTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(event.target.value)
    }

    const onFileChange = (file: File) => {
        setFile(file)
    }

    useEffect(() => {
        switch (index) {
            case 0:
                if (title.length > 0)
                    setNextAvailable(true)
                else
                    setNextAvailable(false)
                break
            case 1:
                if (content.length > 2000 || file !== undefined)
                    setNextAvailable(true)
                else
                    setNextAvailable(false)
        }
    }, [index, title, content, file])

    const onFilterChange = async (media: FilterContent[], title: FilterContent[], geo: FilterContent[]) => {
        setGeoFilter(geo)
        setMediaFilter(media)
        setTitleFilter(title)
        setNextAvailable(true)
    }

    const onClose = () => {
        setTitle("")
        setContent("")
        setFile(undefined)
        setMediaFilter([])
        setTitleFilter([])
        setGeoFilter([])
        setNextAvailable(false)
        setIndex(0)
        close()
    }

    const nextSlide = async () => {
        if (index !== 2) {
            setIndex(index + 1)
        } else {
            await savePost()
        }
    }

    return (<>
        <div style={style.header}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <h4 style={style.title}>{index === 0 && titleEdit !== undefined ? "Edit post" : containerTitles[index]}</h4>
                <CarouselIndicator numberOfDots={4} index={index}/>
            </div>
            <div>
                {index <= 2 ?
                    <>
                        <button style={style.button} onClick={onClose}>Annulla</button>
                        {index === 0 ?
                            null
                            : <button style={style.button} onClick={() => setIndex(index - 1)}>
                                {"Indietro"}
                            </button>}
                        <button style={style.buttonYellow} className={"buttonYellow"}
                                disabled={!nextAvailable || loading}
                                onClick={nextSlide}>
                            {loading ? <Oval
                                height={16}
                                width={16}
                                color={colors.supportDarker}
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor={colors.supportBlack}
                                strokeWidth={8}
                                strokeWidthSecondary={8}/> : "Prossimo passo"}
                        </button>
                    </>
                    : <button style={style.buttonYellow} className={"buttonYellow"} onClick={onClose}>
                        {"Finisci"}
                    </button>}
            </div>
        </div>
        {index === 3 ? <PostResults isNewPost={true}
                                    onClose={onClose}
                                    result={{
                                        documentId: documentId,
                                        title: title,
                                        body: content,
                                        createdAt: "",
                                        lastUpdatedAt: ""
                                    }}/>
            : <div style={style.box}>
                <Carousel selectedItem={index}
                          showArrows={false}
                          showIndicators={false}
                          showStatus={false}
                          onChange={manageNext}
                          showThumbs={false}>
                    <PostTitle onTitleChange={onTitleChange} title={title}/>
                    <PostBody onFileChange={onFileChange} onTextareaChange={onTextareaChange} content={content}/>
                    <PostFilters onFilterChange={onFilterChange} geo={geoFilter} title={titleFilter}
                                 media={mediaFilter} setLocalGeo={setLocalGeoFilter} setIsLocal={setIsLocal}
                                 isLocal={isLocal}/>
                </Carousel>
            </div>}
    </>);
}