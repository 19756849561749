import {style} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import TokenManager from "../../../../common/TokenManager";
import {reset} from "../../../../common/Redux/reducers/queryCounter";
import {useAppDispatch, useAppSelector} from "../../../../common/Redux/hooks";
import {setUserPicture} from "../../../../common/Redux/reducers/userPicture";
import {setAuthorizations} from "../../../../common/Redux/reducers/userAuthorizations";

type Props = {
    onClose: () => void,
    name: string
}

function useOutsideAlerter(ref: React.MutableRefObject<any>, onClose: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export default function ProfileModal({onClose, name}: Props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onClose);
    const navigate = useNavigate();
    const [picture, setPicture] = React.useState<string>("")
    const dispatch = useAppDispatch()
    const userPicture = useAppSelector((state) => state.userPicture.value)

    const logout = () => {
        dispatch(reset(0))
        dispatch(setAuthorizations([]))
        TokenManager.logout()
        navigate("/login")
    }

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserData = async () => {
        if (userPicture.length > 0)
            setPicture(userPicture)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!fetched.ok) {
            return
        }
        let user: {
            profile_picture: string
        } = await fetched.json()
        if (userPicture.length === 0)
            dispatch(setUserPicture(user.profile_picture))
        setPicture(user.profile_picture)
    }

    return (
        <div style={style.backDrop}>
            <div ref={wrapperRef} style={style.modal}>
                <div className={"d-flex justify-content-between align-items-center"}
                     style={style.separator}>
                    <span>{name}</span>
                    {picture.length > 0 ?
                        <img src={picture} alt="" style={style.userPicture} onError={() => setPicture("")}/>
                        : <div style={style.userIcon}>
                            <FontAwesomeIcon icon={faUser} size={"lg"}/>
                        </div>}
                </div>
                <NavLink style={style.edit} to={"profile"}>{"Gestisci il tuo profilo"}</NavLink>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <button style={style.button} onClick={logout}>Log out</button>
                    <span style={style.version}>v1.0 (00001)</span>
                </div>
            </div>
        </div>
    )
}