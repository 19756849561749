import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
    },
    title: {
        fontSize: "22px",
        fontWeight: "700",
        // marginLeft: "20px",
        marginBottom:0
    },
    input: {
        marginLeft: "32px",
        marginRight: "32px",
        width: "calc(100% - 96px)",
        outline: "none",
        border: `1px solid ${colors.supportLight}`,
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
    },
    filterContent: {
        padding: "0px 32px 0 32px",
        width: "100%",
        height: "calc(100% - 132px)",
        overflowY: "scroll",
        paddingRight: "17px",
        boxSizing: "content-box",
        overflowX: "hidden",
    },
    centeredRow: {
        alignItems: "center",
        marginBottom: "8px",
        flexWrap: "unset",
        marginLeft:0
    },
    localRow: {},
    checkbox: {
        width: "1.5em",
        height: "1.5em",
        backgroundColor: "white",
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        cursor: "pointer",
        margin: 0
    },
    checkboxSelected: {
        width: "1.5em",
        height: "1.5em",
        backgroundColor: colors.primaryLight,
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        margin: 0,
        cursor: "pointer",
    },
    text: {
        marginLeft: "15px",
        fontWeight: 400,
        fontSize: "16px",
    },
}