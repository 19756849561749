import React, {useEffect} from "react";
import {style} from "./style";
import {Col, Row} from "react-bootstrap";
import {Filters} from "../FilterByNew/types";
import {FilterConverter} from "../FilterByNew/FilterManager";
import TokenManager from "../../../common/TokenManager";
import {PostsResponse} from "../discovery/type";
import Histogram from "./Charts/Histogram/Histogram";
import Linear from "./Charts/Linear/Linar";
import Donut from "./Charts/Donut/Donut";
import {useNavigate} from "react-router-dom";
import Toast, {ApiError} from "../../../common/Toast";
import WordCloudChart from "./Charts/WordCloud/WordCloud";
import NoResults from "../../NoResults/NoResults";
import FilterByNew, {filterTranslation} from "../FilterByNew/FilterByNew";
import FancyLoader from "../../../common/FancyLoader/FancyLoader";
import Loader from "../../../common/Loader/Loader";
import {useAppSelector} from "../../../common/Redux/hooks";

type Result = {
    authors: number,
    charts: ChartType[],
    contents: number,
    sources: number
}

type ChartType = {
    chartid: number,
    chartname: string,
    charttype: "Histogram" | "Linear" | "Donut" | "Word Cloud"
    "x-axis-title": string,
    "x-axis-metric": string,
    "y-axis-title": string,
    "y-axis-metrics": string,
    datapoints: Datapoint[]
}

export type Datapoint = {
    series: string,
    "x-id": number,
    "x-value-label": string,
    "y-value": number,
    "y-secondary-value": number | null,
}

export interface WordData {
    text: string;
    value: number;
}

export default function MediaAnalytics() {
    const [filterPhrase, setFilterPhrase] = React.useState<string>("")
    const [filters, setFilters] = React.useState<Filters>({
        thematicAreas: [],
        topic_tree: [],
        mediaTypes: [],
        authors: [],
        geoCategories: [],
        entities: [],
        sources: [],
        sourceProfiles: [],
        publicationPeriod: []
    })
    const [charts, setCharts] = React.useState <ChartType[]>()
    const [loading, setLoading] = React.useState <boolean>(false)
    const [authors, setAuthors] = React.useState <string>("0")
    const [contents, setContents] = React.useState <string>("0")
    const [sources, setSources] = React.useState <string>("0")
    const navigate = useNavigate();
    const userAuth = useAppSelector((state) => state.userAuthorizations.value)

    useEffect(() => {
        getMediaAnalitycs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const getMediaAnalitycs = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        // setLoadingContent(true)
        let params = FilterConverter(filters)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/media-analytics/${params === "" ? "?publicationPeriod=4" : `?${params}`}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        setLoading(false)
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            setAuthors("0")
            setSources("0")
            setContents("0")
            setCharts([])
            Toast(data)
            return
        }
        const data: (PostsResponse & { results: Result }) = await fetched.json()
        setAuthors(numberWithPoints(data.results.authors))
        setSources(numberWithPoints(data.results.sources))
        setContents(numberWithPoints(data.results.contents))
        setCharts(data.results.charts)
    }

    const numberWithPoints = (x: number): string => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    useEffect(() => {
        let newPhrase = ""
        for (const filterKey of Object.keys(filters)) {
            if (filterKey !== "publicationPeriod" && filters[filterKey].length > 0) {
                if (filterKey !== "geoCategories") {
                    switch (filters[filterKey].length) {
                        case 1:
                            newPhrase += `${filters[filterKey][0].name}, `
                            break
                        case 2:
                            newPhrase += `${filters[filterKey][0].name}, ${filters[filterKey][1].name}, `
                            break
                        default:
                            newPhrase += `${filters[filterKey][0].name} + ${filters[filterKey].length - 1} ${filterTranslation[filterKey]}, `
                    }
                }
            }
        }
        if (filters["geoCategories"].length > 0) {
            switch (filters["geoCategories"].length) {
                case 1:
                    if (filters["geoCategories"][0].name === "Nazionale") {
                        newPhrase += `${filters["geoCategories"][0].name}`
                        break
                    }
                    newPhrase += `${filters["geoCategories"][0].subname}, `
                    break
                case 2:
                    newPhrase += `${filters["geoCategories"][0].subname}, ${filters["geoCategories"][1].subname}, `
                    break
                default:
                    newPhrase += `${filters["geoCategories"][0].subname} + ${filters["geoCategories"].length - 1} Regioni, `
            }
        }
        if (newPhrase.substring(newPhrase.length - 2) === ", ")
            newPhrase = newPhrase.substring(0, newPhrase.length - 2)
        setFilterPhrase(newPhrase)
    }, [filters])

    useEffect(() => {
        if (!userAuth.includes(10) && userAuth.length > 0)
            navigate("/profile")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAuth])

    return (
        <div style={style.box}>
            {userAuth.includes(10) ? <>
                    <FilterByNew setActiveFilters={setFilters} loading={loading} discovery={false}/>
                    <div style={style.content}>
                        <div style={style.header}>
                            <div className={"d-flex justify-content-between align-items-center mb-3"}>
                                <h2 style={style.title}>Media Analytics</h2>
                                {filterPhrase.length > 0 ?
                                    <span style={{maxWidth: "50%"}}>{`Risultati per: ${filterPhrase}.`}</span> : null}
                            </div>
                            <p style={style.subtitle}>
                                {"Analizza trends e patterns, genera insights e crea le infografiche più adatte alle tue esigenze."}
                            </p>
                            {!loading ? <p style={style.subtitle}>
                                {`${contents} Contenuti, ${authors} Autori, ${sources} Testate.`}
                            </p> : null}
                        </div>
                        {loading ? <div style={{height: "calc(100vh - 400px)", display: "flex", alignItems: "center"}}>
                                <FancyLoader/></div>
                            : <Row className={"w-100"}>
                                {charts && charts.length > 0 ? charts.map((chart, index) => {
                                    if (chart.charttype === "Histogram") {
                                        return <Col sm={chart.chartname === "Incidenza per Topics (top10)" ? 12 : 6}
                                                    style={style.analyticsBox} key={`chart-${index}`}>
                                            <div style={style.analyticsBoxContent}>
                                                <h3 style={style.analyticsBoxTitle}>{chart.chartname}</h3>
                                                <Histogram datapoints={chart.datapoints}/>
                                            </div>
                                        </Col>
                                    } else if (chart.charttype === "Linear") {
                                        return <Col sm={chart.chartname === "Trend per Topics (top5)" ? 12 : 6}
                                                    style={style.analyticsBox}
                                                    key={`chart-${index}`}>
                                            <div style={style.analyticsBoxContent}>
                                                <h3 style={style.analyticsBoxTitle}>{chart.chartname}</h3>
                                                <Linear datapoints={chart.datapoints}/>
                                            </div>
                                        </Col>
                                    } else if (chart.charttype === "Donut") {
                                        return <Col sm={6} style={style.analyticsBox} key={`chart-${index}`}>
                                            <div style={style.analyticsBoxContent}>
                                                <h3 style={style.analyticsBoxTitle}>{chart.chartname}</h3>
                                                <Donut datapoints={chart.datapoints}/>
                                            </div>
                                        </Col>
                                    } else if (chart.charttype === "Word Cloud") {
                                        return <Col sm={12} style={style.analyticsBox} key={`chart-${index}`}>
                                            <div style={style.analyticsBoxContent}>
                                                <h3 style={style.analyticsBoxTitle}>{chart.chartname}</h3>
                                                <WordCloudChart datapoints={chart.datapoints}/>
                                            </div>
                                        </Col>
                                    } else return null
                                }) : <NoResults
                                    text={"L'Intelligenza Artificiale di Point-out non ha trovato risultati pertinenti alla tua ricerca. Point-out sta lavorando per ampliare il database e offrirti la massima accuratezza possibile."}/>}
                            </Row>}
                    </div>
                </>
                : userAuth.length === 0 ? <Loader/>
                    : null}
        </div>
    );
}