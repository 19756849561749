import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    background: {
        backgroundColor: "rgba(29,29,27,0.3)",
        width: "100%",
        height: "100%",
        zIndex: 1200,
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    box: {
        backgroundColor: colors.supportWhite,
        borderRadius: "40px",
        padding: "40px"
    },
    title: {
        fontSize: "32px",
        fontWeight: "700",
        color: colors.supportBlack,
        margin: 0,
        marginBottom: "32px"
    },
    label: {
        width: "100%",
        fontSize: "16px",
        fontWeight: "600",
        color: colors.supportBlack,
        marginBottom: "4px"
    },
    input: {
        width: "100%",
        outline: "none",
        border: `1px solid ${colors.supportLight}`,
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        marginBottom: "32px",
        boxShadow: "0px 2px 1px 0px #1018280D",
    },
    cancel: {
        width: "calc(50% - 8px)",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        outline: "none",
        fontSize: "16px",
        fontWeight: "600",
        color: colors.supportBlack,
        boxShadow: "0px 2px 1px 0px #1018280D",
        border: `1px solid ${colors.supportLight}`,
        marginRight: "8px"
    },
    save: {
        width: "calc(50% - 8px)",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: "none",
        outline: "none",
        fontSize: "16px",
        fontWeight: "600",
        color: colors.supportBlack,
        boxShadow: "0px 2px 1px 0px #1018280D",
        backgroundColor: colors.primaryLight,
        marginRight: "8px"
    }
}