import React from "react";
import {style} from "./style";

type Props = {
    text: string,
    className?: string
}
export default class LoginSubtitle extends React.Component<Props, any> {
    render() {
        return (
            <p className={this.props.className} style={style}>{this.props.text}</p>
        );
    }
}