import Storage from "./Storage";

export default class TokenManager {
    public static setAccessToken(value: string) {
        Storage.getStorage().set("accessToken", value)
    }

    public static async getAccessToken(): Promise<string | null> {
        let token = Storage.getStorage().get("accessToken")
        if (token === null)
            return null;
        token = this.validateToken(token)
        if (token === null)
            return await this.login()
        return token
    }

    public static setFactorToken(value: string, factor: 1 | 2) {
        Storage.getStorage().set(`token_${factor}f`, value)
    }

    public static getFactorToken(factor: 1 | 2): string | null {
        let token: string | null = Storage.getStorage().get(`token_${factor}f`)
        if (token === null)
            return null;

        return this.validateToken(token);
    }

    private static validateToken(token: string): string | null {
        try {
            let payload = JSON.parse(window.atob(token.split(".")[1]))
            const exp = new Date(payload.exp * 1000)
            const now = new Date();
            if (exp < now) {
                return null;
            }
        } catch (e) {
            //console.log(e)
            return null;
        }
        return token
    }

    public static async login(): Promise<string | null> {
        const token_1f = TokenManager.getFactorToken(1)
        const token_2f = TokenManager.getFactorToken(2)
        if (token_1f === null || token_2f === null) {
            return null
        }
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token_1f: token_1f,
                token_2f: token_2f,
            })
        })
        if (!fetched.ok) {
            // let data: ApiError = await fetched.json()
            // Toast(data)
            return null
        }
        let data: { access_token: string } = await fetched.json()
        TokenManager.setAccessToken(data.access_token)
        return data.access_token
    }

    public static logout() {
        TokenManager.setFactorToken("", 1)
        TokenManager.setFactorToken("", 2)
        TokenManager.setAccessToken("")
    }
}