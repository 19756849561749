import {style} from "./style";
import {MagnifyingGlass} from "react-loader-spinner";
import {colors} from "../colors";
import React from "react";

type Props = {
    size?: number
}
export default function FancyLoader({size}: Props) {
    return (
        <div style={style.box}>
            <MagnifyingGlass
                visible={true}
                height={size ? size : 150}
                width={size ? size : 150}
                ariaLabel="MagnifyingGlass-loading"
                wrapperStyle={{}}
                wrapperClass="MagnifyingGlass-wrapper"
                glassColor ={colors.primaryLightOpacity}
                color = {colors.primaryDarker}
            />
        </div>
    )
}