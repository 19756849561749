import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        userSelect: "none",
        minHeight: "80px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: colors.primaryLight,
        alignItems: "center",
        paddingLeft: "32px",
        paddingRight: "32px",
        // position: "fixed",
        // zIndex: 9000,
        justifyContent: "space-between"
    },
    link: {
        fontSize: "18px",
        fontWeight: 700,
        textDecoration: "none",
        color: colors.supportBlack,
        padding: "8px",
        marginLeft: "8px",
        marginRight: "8px"
    },
    activeLink: {
        fontSize: "18px",
        fontWeight: 700,
        textDecoration: "none",
        color: colors.supportBlack,
        // height:"43px",
        padding: "8px",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px",
        marginLeft: "8px",
        marginRight: "8px"
    },
    support: {
        backgroundColor: "transparent",
        width: "40px",
        height: "40px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        borderRadius:"8px",
        textDecoration:"none",
        color:colors.supportBlack,
        marginRight:"16px"
    },
    supportActive: {
        backgroundColor: colors.supportWhite,
        width: "40px",
        height: "40px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        borderRadius:"8px",
        textDecoration:"none",
        color:colors.supportBlack,
        marginRight:"16px"
    },
    userIcon: {
        backgroundColor: colors.supportWhite,
        height: "48px",
        width: "48px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "20px",
        cursor: "pointer"
    },
    userPicture: {
        height: "48px",
        width: "48px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor:colors.supportWhite
    },
    lock:{
        marginLeft:"5px"
    }
}