import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: CSS.Properties = {
    border: `solid 1px ${colors.supportLight}`,
    borderRadius: "8px",
    padding: "12px 16px 12px 16px",
    color: colors.supportBlack,
    width: "100%",
    fontSize: "16px",
    fontWeight: 400,
    boxShadow: "0px 2px 1px 0px #1018280D"
}