import React, {useState} from "react";
import {style} from "./style";
import LoginPointOutTitle from "../components/LoginPointOutTitle/LoginPointOutTitle";
import LoginTitle from "../components/LoginTitle/LoginTitle";
import LoginLabel from "../components/LoginLabel/LoginLabel";
import LoginInput from "../components/LoginInput/LoginInput";
import LoginButton from "../components/LoginButton/LoginButton";
import LoginSecondaryButton from "../components/LoginSecondaryButton/LoginSecondaryButton";
import LoginSideSlide from "../components/LoginSideSlide/LoginSideSlide";
import {useNavigate} from "react-router-dom";
import Toast, {ApiError} from "../../../common/Toast";
import TokenManager from "../../../common/TokenManager";

export default function Login() {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const setEmailLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const setPasswordLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const login = async () => {
        setLoading(true)
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login/password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            }),
        })
        setLoading(false)
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        let data: { token_1f: string, password_change_needed: boolean } = await fetched.json()
        TokenManager.setFactorToken(data.token_1f, 1)
        navigate('/first-login', {
            state: {password_change_needed: data.password_change_needed}
        })
    }

    return (
        <div style={style.page}>
            <div style={style.column}>
                <div style={style.content}>
                    <div style={style.logo}>
                        <LoginPointOutTitle/>
                    </div>
                    <iframe title={"Login"} name="votar" style={{display: "none"}}></iframe>
                    <form action="about:blank" onSubmit={login} target="votar">
                        <LoginTitle className={"mb32"} text={"Welcome back!"}/>
                        <LoginLabel text={"E-mail"}/>
                        <LoginInput className={"mb32"} type={"email"} placeholder={"Inserisci la tua mail"}
                                    setValue={setEmailLocal}/>
                        <LoginLabel text={"Password"}/>
                        <LoginInput className={"mb32"} type={"password"} placeholder={"Inserisci la tua password"}
                                    setValue={setPasswordLocal}/>
                        <LoginButton className={"mb16"} text={"Login"} loading={loading}/>
                        <LoginSecondaryButton text={"Ho dimenticato la password"} onClick={() => navigate('/forgot')}/>
                    </form>
                </div>
            </div>
            <div style={style.column}>
                <LoginSideSlide title={"Lo sapevi?"}
                                text={"Copriamo più di 1.500 testate. Analizziamo i contenuti relativi a tutti i settori dell'economia, dall'ICT ai settori più analogici"}/>
            </div>
        </div>
    );
}