export default class Storage {

    private static storage: Storage | undefined;

    set(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    get(key: string) {
        return localStorage.getItem(key)
    }

    deleteKey(key: string) {
        localStorage.removeItem(key)
    }

    deleteAll() {
        localStorage.clear()
    }

    public static getStorage(): Storage {
        let storage = this.storage
        if (storage === undefined)
            storage = new Storage()
        return storage
    }
}