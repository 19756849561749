import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from "../../components/loginForm/Login/Login";
import ForgotPassword from "../../components/loginForm/ForgotPassword/ForgotPassword";
import FirstLogin from "../../components/loginForm/FirstLogin/FirstLogin";
import ChangePassword from "../../components/loginForm/ChangePassword/ChangePassword";
import FirstLoginCarousel from "../../components/loginForm/FirstLoginCarousel/FirstLoginCarousel";
import Discovery from "../../components/home/discovery/Discovery";
import Home from "../../components/home/Home";
import SmartTargeting from "../../components/home/smartTargeting/SmartTrgeting";
import MediaAnalytics from "../../components/home/MediaAnalytics/MediaAnalytics";
import React from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Profile from "../../components/home/profile/Profile/Profile";
import Support from "../../components/home/profile/Support/Support";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        children: [
            {
                path: "smart-targeting",
                element: <SmartTargeting/>
            },
            {
                path: "discovery",
                element: <Discovery/>
            },
            {
                path: "media-analytics",
                element: <MediaAnalytics/>
            },
            {
                path: "profile",
                element: <Profile/>
            },
            {
                path: "support",
                element: <Support/>
            }
        ]
    },
    {
        path: "login",
        element: <Login/>,
    },
    {
        path: "forgot",
        element: <ForgotPassword/>,
    },
    {
        path: "first-login",
        element: <FirstLogin/>,
    },
    {
        path: "change-password",
        element: <ChangePassword/>,
    },
    {
        path: "carousel",
        element: <FirstLoginCarousel/>,
    }
]);

export const Navigation = () => {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <RouterProvider router={router}/>
        </>
    )
}