import CSS from "csstype";

export const style: { [key: string]: CSS.Properties } = {
    page: {
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    column: {
        width: "50%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        maxWidth: "497px",
        padding: "40px",
        flexDirection:"column"
    },
    slide: {
        // marginTop: "194px",
        // height: "calc(100vh - 234px)",
        overflowY: "hidden",
        height: "calc(75vh - 80px)",
        width: "100%"
    },
    logo: {
        // position: "absolute",
        // left: "40px",
        // top: "133px"
        height:"25vh",
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"left"
    },
}