import {faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

type Props = {
    onClick: (rate: number) => void
}

export default function Ratings({onClick}: Props) {
    const [active, setActive] = useState<number>(0)
    const [sent, setSent] = useState<boolean>(false)
    const rows = []

    const hover = (index: number) => {
        if (!sent) {
            setActive(index)
        }
    }
    const out = () => {
        if (!sent) {
            setActive(0)
        }
    }

    const click = (index: number) => {
        if (!sent) {
            setSent(true)
            setActive(index)
            onClick(index)
        }
    }

    for (let i = 0; i < 5; i++) {
        rows.push(
            <FontAwesomeIcon icon={faStar} color={active >= i + 1 ? "#ffbc0b" : "#ccc"}
                             className={"px-1"}
                             onMouseEnter={() => hover(i + 1)}
                             onMouseLeave={out}
                             onClick={() => click(i + 1)}
                             size={"2xl"}
                             style={{cursor: "pointer"}}/>)
    }


    return (
        <div>
            {rows}
        </div>
    )
}