import CSS from "csstype";
import {colors} from "../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        boxShadow: "0px 2px 1px 0px #1018280D",
        display:"inline-block",
        cursor:"pointer",
        margin: "8px 4px 8px 4px",
        alignItems: "center",
        width:"fit-content",
        userSelect: "none"
    },
    checkedBox: {
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `solid 1px ${colors.primaryLight}`,
        boxShadow: "0px 2px 1px 0px #1018280D",
        display:"inline-block",
        cursor:"pointer",
        margin: "8px 4px 8px 4px",
        alignItems: "center",
        width:"fit-content",
        userSelect: "none"
    },
    checkbox: {
        width: "1.3em",
        height: "1.3em",
        backgroundColor: "white",
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        cursor: "pointer",
        margin: 0
    },
    checkboxSelected: {
        width: "1.3em",
        height: "1.3em",
        backgroundColor: colors.primaryLight,
        borderRadius: "50%",
        verticalAlign: "middle",
        border: "1px solid #ddd",
        appearance: "none",
        outline: "none",
        margin: 0,
        cursor: "pointer",
    },
    text: {
        marginLeft: "15px",
        fontWeight: 600,
        fontSize: "16px",
    },
}