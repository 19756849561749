import CSS from "csstype";
import {colors} from "../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    noResult: {
        color: colors.supportDarker,
        marginTop: "16px",
        fontSize: "20px",
        maxWidth: "50%",
        textAlign: "center"
    }
}