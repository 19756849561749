import {style} from "./style";
import TokenManager from "../../../../common/TokenManager";
import Toast, {ApiError} from "../../../../common/Toast";
import {useNavigate} from "react-router-dom";
import React from "react";
import Loader from "../../../../common/Loader/Loader";
import randomString from "../../../../common/randomString";
import {Filters} from "../types";

type Props = {
    filters: Filters,
    onClose: () => void,
    discovery?: boolean
}

export default function SaveFilters({filters, onClose, discovery = true}: Props) {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>("")
    const navigate = useNavigate();

    const setNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const saveFilters = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                key: `saved-filter-${discovery ? "discovery" : "media-analytics"}-${randomString(16)}`,
                value: {
                    name,
                    filters
                },
                tag: `saved-filter-${discovery ? "discovery" : "media-analytics"}`
            })
        })
        if (!fetched.ok) {
            setLoading(false)
            const error: ApiError = await fetched.json()
            Toast(error)
            return
        }
        setLoading(false)
        onClose()
    }

    return (
        <div style={style.background}>
            <div style={style.box}>
                <h3 style={style.title}>{"Salva i filtri"}</h3>
                <label style={style.label}>{"Inserisci un nome che puoi ricordare"}</label>
                <input style={style.input} value={name} onChange={setNameValue}/>
                <div className={"d-flex flex-row justify-content-between"}>
                    <button style={style.cancel} onClick={onClose}>{"Cancella"}</button>
                    <button style={style.save} disabled={name.length === 0} onClick={saveFilters}>
                        {loading ? <Loader size={16}/> : "Salva i filtri selezionati"}
                    </button>
                </div>
            </div>
        </div>
    );
}