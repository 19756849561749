import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'

interface UserPictureState {
    value: string
}

const initialState: UserPictureState = {
    value: "",
}

export const userPictureSlice = createSlice({
    name: 'userPicture',
    initialState,
    reducers: {
        setUserPicture: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setUserPicture} = userPictureSlice.actions

export const selectCount = (state: RootState) => state.userPicture.value

export default userPictureSlice.reducer