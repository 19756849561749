import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React, {useEffect} from "react";
import {Datapoint} from "../../MediaAnalytics";

type Props = {
    datapoints: Datapoint[]
}

const graphColors = ["#ED5565", "#FFCE54", "#48CFAD", "#5D9CEC", "#EC87C0", "#CCD1D9", "#FC6E51", "#A0D468", "#4FC1E9", "#AC92EC", "#F5F7FA", "#656D78"]
// const graphColors = ["#820401", "#1DE4BD",  "#1AC9E6", "#DE542C", "#176BA0", "#EF7E32", "#EE9A3A", "#EA7369", "#EABD3B", "#DB4CB2", "#E7E34E", "#7D3AC1", "#F7F4BF"]
// const graphColors = ["#820401", "#C02323", "#DE542C", "#EF7E32", "#EE9A3A", "#EABD3B", "#E7E34E", "#F7F4BF"]

export default function Linear({datapoints}: Props) {

    const [data, setData] = React.useState<{ [key: string]: number | string }[]>([])
    const [names, setNames] = React.useState<string[]>([])

    useEffect(() => {
            const dataSorter: { [key: string]: { [key: string]: number | string } } = {}
            const newNames: string[] = []
            let sortedDatapoints = datapoints.sort((a, b) => {
                if (a["x-id"] > b["x-id"])
                    return 1
                else if (a["x-id"] < b["x-id"])
                    return -1
                return 0
            })
            for (const datapoint of sortedDatapoints) {
                if (dataSorter[datapoint["x-value-label"]] === undefined) {
                    dataSorter[datapoint["x-value-label"]] = {}
                }
                if (newNames.indexOf(datapoint["series"]) === -1)
                    newNames.push(datapoint["series"])
                dataSorter[datapoint["x-value-label"]][datapoint.series] = datapoint["y-value"]
            }
            const newData: { [key: string]: number | string }[] = []
            for (const key of Object.keys(dataSorter)) {
                newData.push({
                    ...dataSorter[key],
                    label: key,
                })
            }
            setNames(newNames)
            setData(newData)
        }, [datapoints]
    )


    return (
        <ResponsiveContainer width="100%" height="90%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="label"/>
                {/*<XAxis dataKey="label" angle={-45} textAnchor="end" allowDataOverflow={false} height={1} interval={10000}/>*/}
                <YAxis tickFormatter={(tick) => {
                    return `${parseFloat(tick+"").toLocaleString('it')}`;
                }}/>
                {/*@ts-ignore*/}
                <Tooltip />
                {/*<Tooltip formatter={( value, name, props) => `formatted ${numberWithPoints(parseInt(value))}`}/>*/}
                <Legend formatter={(value, entry, index) => <span style={{color: "#000000"}}>{value}</span>}/>
                {names.map((name, index) => <Line key={`line-${index}`} type="monotone" dataKey={name}
                                                  stroke={graphColors[index]}/>)}
            </LineChart>
        </ResponsiveContainer>
    )
}