import {ResponsiveContainer} from "recharts";
import React, {} from "react";
import {Datapoint, WordData} from "../../MediaAnalytics";
import {Wordcloud} from "@visx/wordcloud";
import {Text} from "@visx/text";
import {scaleLog} from "@visx/scale";

type Props = {
    datapoints: Datapoint[]
}

// const graphColors = ["#ED5565", "#FFCE54", "#48CFAD", "#5D9CEC", "#EC87C0", "#CCD1D9", "#FC6E51", "#A0D468", "#4FC1E9", "#AC92EC", "#F5F7FA", "#656D78"]
// const graphColors = ["#820401", "#1DE4BD",  "#1AC9E6", "#DE542C", "#176BA0", "#EF7E32", "#EE9A3A", "#EA7369", "#EABD3B", "#DB4CB2", "#E7E34E", "#7D3AC1", "#F7F4BF"]
// const graphColors = ["#820401", "#C02323", "#DE542C", "#EF7E32", "#EE9A3A", "#EABD3B", "#E7E34E", "#F7F4BF"]
const colors2 = ["#C02323", "#DE542C", "#EF7E32", "#EE9A3A", "#EABD3B", "#E7E34E"]
export default function WordCloudChart({datapoints}: Props) {
    const fixedValueGenerator = () => 0.5;
    const fontSizeSetter = (datum: WordData) => fontScale(datum.value);
    const words = datapoints.map((datapoint) => ({
        text: datapoint["x-value-label"],
        value: datapoint["y-value"]
    }))
    const fontScale = scaleLog({
        domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
        range: [30, 100],
    });

    return (
        <ResponsiveContainer width="100%" height="90%">
            <Wordcloud spiral={'rectangular'} font={'Impact'} random={fixedValueGenerator}
                       fontSize={fontSizeSetter} padding={2} rotate={0} width={9000} height={9000}
                       words={words}>
                {(cloudWords) =>
                    cloudWords.map((w, i) => (
                        <Text
                            key={w.text}
                            fill={colors2[i % colors2.length]}
                            textAnchor={'middle'}
                            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                            fontSize={w.size}
                            fontFamily={w.font}>
                            {w.text}
                        </Text>
                    ))
                }
            </Wordcloud>
        </ResponsiveContainer>
    )
}