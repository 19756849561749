import React, {createRef} from "react";
import {style} from "./style";

type Props = {
    title: string,
    text: string,
    className?: string
}

export default class LoginSideSlide extends React.Component<Props, any> {
    private titleRef = createRef<HTMLHeadingElement>()
    state = {
        lines: []
    }

    componentDidMount() {
        const start = 39.1875
        const lines = []
        for (let i = 0; i <= Math.ceil(((this.titleRef.current?.offsetWidth ?? 0) / 40)); i++) {
            lines.push(
                <rect key={i} x={`${31.5273 + start * i}`}
                      width="10"
                      height="63.0549"
                      transform={`rotate(30 ${31.5273 + start * i} 0)`}
                      fill="white"/>)
        }
        this.setState({lines})
    }

    render() {

        return (
            <div className={this.props.className} style={style.slide}>
                <div style={style.slideContent}>
                    <svg  style={style.shape} width="354" height="60" viewBox="0 0 354 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {this.state.lines}
                    </svg>
                    <h1 ref={this.titleRef} style={style.title}>{this.props.title}</h1>
                    <p style={style.text}>{this.props.text}</p>
                </div>
            </div>
        );
    }
}