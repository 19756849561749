import {style} from "./style";
import React, {useEffect} from "react";
import {AuthorsResults, ContentsResult, SourcesResults} from "../home/discovery/type";
import * as CSV from "csv/browser/esm";
import TokenManager from "../../common/TokenManager";
import {useNavigate} from "react-router-dom";

type Props = {
    loading: boolean,
    tabIndex: number,
    authors: AuthorsResults[],
    contents: ContentsResult[],
    titles: SourcesResults[],
    primary: boolean
}
export default function DownloadCSV({loading, tabIndex, authors, contents, titles, primary}: Props) {
    const [userAuth, setUserAuth] = React.useState<number[]>([])
    const navigate = useNavigate();

    const contentCsvAssemble = (contents: ContentsResult[]): string[][] => {
        let csv = [["Titolo", "Autore", "Testata", "Data"]]
        for (const content of contents) {
            csv.push([
                content.title,
                content.authors?.length > 0 && content.authors[0].name ? content.authors[0].name : "",
                content.source.name,
                content.publicationDate.toString(),
                // content.snippet.replace(/(\r\n|\n|\r)/gm, "")
            ])
        }
        return csv
    }

    const authorCsvAssemble = (authors: AuthorsResults[]): string[][] => {
        let csv = [["Nome", "Cognome", "Ruolo", "Email", "LinkedIn", "Twitter", "Testata"]]
        for (const author of authors) {
            let nameArray = author.author.name.split(" ")
            csv.push([
                nameArray[0],
                nameArray.length > 1 ? nameArray[nameArray.length - 1] : "",
                author.sources && author.sources.length > 0 && author.sources[0].role !== null ? author.sources[0].role : "",
                author.emails && author.emails.length > 0 ? author.emails[0] : "",
                author.linkedin?.name ? author.linkedin.name : "",
                author.twitter?.name ? author.linkedin.name : "",
                author.sources.length > 0 && author.sources[0].name !== null ? author.sources[0].name : ""
            ])
        }
        return csv
    }

    const sourcesCsvAssemble = (sources: SourcesResults[]): string[][] => {
        let csv = [["Nome", "Url", "Profilo", "Copertura geografica", "Copertura locale", "Città Redazione", "Indirizzo (Via, Civico)", "CAP", "Email Redazione", "Centralino Redazione"]]
        for (const source of sources) {
            let address = ""
            let cap = ""
            let city = ""
            if (source.address !== null) {
                const splitAddress = source.address.split(",")
                address = splitAddress[0] ? splitAddress[0] : ""
                cap = splitAddress[1] ? splitAddress[1] : ""
                city = splitAddress[2] ? splitAddress[2] : ""
            }
            csv.push([
                source.source.name,
                source.website,
                source.sourceProfile.name,
                source.geoCategory.name,
                source.geoCategory.name === "Nazionale" ? "" : source.geoCategory.subname,
                city,
                address,
                cap,
                source.emails ? source.emails[0] : "",
                source.phones ? source.phones.join(", ") : ""
            ])
        }
        return csv
    }

    const csvContent = contentCsvAssemble(contents)
    const csvAuthors = authorCsvAssemble(authors)
    const csvTitles = sourcesCsvAssemble(titles)

    const create = () => {
        CSV.stringify(tabIndex === 0 ? csvContent : tabIndex === 1 ? csvAuthors : csvTitles, {
            delimiter: ';',
            bom: true
        }, (err, output) => {
            downloadFile(output, tabIndex === 0 ? "contenuti" : tabIndex === 1 ? "autori" : "testate")
        });
    }

    const downloadFile = (data: string, fileName: string) => {
        const blob = new Blob([data], {type: "text/csv"})

        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const getUserData = async () => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!fetched.ok) {
            return
        }
        let user: {
            authorizations: number[]
        } = await fetched.json()
        setUserAuth(user.authorizations)
    }
    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!userAuth.includes(40))
        return null
    return (
        <button
            style={loading ? primary ? style.disabledPrimary : style.disabledSecondary : primary ? style.activePrimary : style.activeSecondary}
            onClick={create}>
            Download CSV
        </button>
    )
}