import React from "react";
import LoginTitle from "../../components/LoginTitle/LoginTitle";
import LoginButton from "../../components/LoginButton/LoginButton";
import {style} from "./style";
import LoginLabel from "../../components/LoginLabel/LoginLabel";
import LoginInput from "../../components/LoginInput/LoginInput";

type Props = {
    nextSlide: () => void,
    firstName: string,
    lastName: string,
    company: string,
    setJobTitle: (jobTitle: string) => void,
}
export default function Detail2({nextSlide, firstName, lastName, company, setJobTitle}: Props) {
    const [jobTitle, setJobTitleString] = React.useState<string>("")

    const setJobTitleLocal = (jobTitle: React.ChangeEvent<HTMLInputElement>) => {
        setJobTitleString(jobTitle.target.value)
    }

    const finish = () => {
        setJobTitle(jobTitle)
        nextSlide()
    }

    return (<div style={{
            width: "100%",
            height: "calc(100vh - 234px)",
            overflow: "hidden"
        }}>
            <iframe title={"Detail2"} name="votar" style={{display: "none"}}></iframe>
            <div style={{
                width: "100%",
                height: "calc(75vh - 80px)",
                overflowY: "scroll",
                paddingRight: "17px",
                boxSizing: "content-box"
            }}>
                <form style={style} action="about:blank" onSubmit={finish} target="votar">
                    <LoginTitle className={"mb24"} text={"Inserisci il tuo nome, per favore."}/>
                    <LoginLabel text={"Il tuo nome"}/>
                    <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={"Il tuo nome"}
                                setValue={() => {
                                }} value={`${firstName} ${lastName}`} disabled={true}/>
                    {/*<LoginLabel text={"Il tuo cognome"}/>*/}
                    {/*<LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={"Il tuo cognome"}*/}
                    {/*            setValue={() => {*/}
                    {/*            }} value={lastName} disabled={true}/>*/}
                    <LoginLabel text={"Company"}/>
                    <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={"Dove lavori?"}
                                setValue={() => {
                                }} value={company} disabled={true}/>
                    <LoginLabel text={"Professione"}/>
                    <LoginInput className={"mb32 unsetHW"} type={"text"} placeholder={"Qual è il tuo ruolo professionale?"}
                                setValue={setJobTitleLocal}/>
                    <LoginButton className={"mb32"} text={"Prossimo"} disabled={jobTitle.length < 3}/>
                </form>
            </div>
        </div>
    );
}