import React from "react";
import pc from "../../assets/png/pc.png";
import newspaper from "../../assets/png/newspaper.png";
import {style} from "../home/FilterByNew/FilterTypes/MultipleSelect/style";

type Props = {
    category: string,
    checked: boolean,
    handleOnChange: () => void
}
export default class CheckboxFilter extends React.Component<Props, any> {
    render() {
        return (
            <div style={style.selectable}>
                <input style={style.checkBox}
                       type={"checkbox"}
                       checked={this.props.checked}
                       onChange={() => this.props.handleOnChange()}/>
                {this.props.category === "Stampa cartacea" ?
                    <img src={newspaper} alt={""} style={{width: "16px", height: "auto", marginRight: "5px"}}/> : null}
                {this.props.category === "Stampa digitale" ?
                    <img src={pc} alt={""} style={{width: "16px", height: "auto", marginRight: "5px"}}/> : null}
                <span>{this.props.category}</span>
            </div>
        );
    }
}