import {style} from "./style";
import React, {useEffect} from "react";
import {ContentsResult} from "../discovery/type";

type Props = {
    post: ContentsResult | undefined,
    close: () => void,
    discovery?: boolean
}

export default function ArticleOverlay({post, close, discovery = false}: Props) {
    const [authors, setAuthors] = React.useState<string>("")

    useEffect(() => {
        if (post !== undefined) {
            let authors = ""
            let authorsLength = post.authors?.length ? post.authors?.length : -1
            for (let i = authorsLength - 1; 0 <= i; i--) {
                authors = ""
                switch (i) {
                    case 0:
                        authors += `${post.authors[i].name} `//todo
                        break
                    case 1:
                        authors += `${post.authors[i].name} e `
                        break
                    default:
                        authors += `${post.authors[i].name}, `
                        break
                }
            }
            setAuthors(authors)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (post === undefined)
        return null
    return (
        <div style={discovery ? style.box : style.box2}>
            <div style={discovery ? style.modal : style.modal2}>
                <div style={style.row}>
                    <h3>{post.title}</h3>
                    <button style={style.button} onClick={close}>{"Chiudi"}</button>
                </div>
                <p>{post.snippet}</p>
                <p style={style.footer}>
                    {post.authors?.length > 0 ?
                        <>{"di "}<span style={style.bold}>
                            {authors}
                        </span>
                        </>
                        : null}
                    {post.source?.name ? <>
                            {"in "}
                            <span style={style.bold}>
                            {`${post.source.name} `}
                        </span></>
                        : null}
                    {"il "}
                    <span style={style.bold}>
                        {post.publicationDate}
                    </span>{/*todo dynamic date*/}
                </p>
            </div>
        </div>
    )
}