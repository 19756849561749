import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'

interface QueryCounterState {
    value: number
}

const initialState: QueryCounterState = {
    value: 0,
}

export const queryCounterSlice = createSlice({
    name: 'queryCounter',
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        reset: (state, action: PayloadAction<number>) => {
            state.value += action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {increment, decrement,reset} = queryCounterSlice.actions

export const selectCount = (state: RootState) => state.queryCounter.value

export default queryCounterSlice.reducer