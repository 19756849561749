import {configureStore} from '@reduxjs/toolkit'
import queryCounterReducer from './reducers/queryCounter'
import userAuthorizationsReducer from './reducers/userAuthorizations'
import userPictureReducer from './reducers/userPicture'

export const store = configureStore({
    reducer: {
        queryCounter: queryCounterReducer,
        userAuthorizations: userAuthorizationsReducer,
        userPicture: userPictureReducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch