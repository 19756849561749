import React from "react";
import {style} from "./style";

type Props = {
    handleOnChange: () => void,
    text: string,
    checked: boolean
}

export default class InterestCheckBox extends React.Component<Props, any> {

    render() {
        return (
            <div style={!this.props.checked ?style.box:style.checkedBox} onClick={this.props.handleOnChange}>
                <input type={"checkbox"} style={this.props.checked ? style.checkboxSelected : style.checkbox}/>
                <span style={style.text}>{this.props.text}</span>
            </div>
        );
    }
}