import {style} from "./style";
import React from "react";
import TokenManager from "../../common/TokenManager";
import {useNavigate} from "react-router-dom";
import {colors} from "../../common/colors";
import Loader from "../../common/Loader/Loader";
import Ratings from "../../common/Ratings";

type Props = {
    subject: string,
    requestId: string
}
export default function QueryFeedback({subject, requestId}: Props) {
    const [rating, setRating] = React.useState<number>(0)
    const [visible, setVisible] = React.useState<boolean>(true)
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate();

    const handleRating = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        await fetch(`${process.env.REACT_APP_BASE_URL}/feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "requestId": requestId,
                "stars": rating
            })
        })
        setLoading(false)
        setVisible(false)
    }

    return (
        <div style={style.box} className={visible ? "" : "d-none"}>
            <p style={style.mainText}>
                {`Consideri la lista ${subject} suggerite dalla nostra Intelligenza Artificiale soddisfacenti per il tuo scopo?`}
            </p>
            <p style={style.secondaryText}>
                {`Valuta i suggerimenti cliccando sulle stelle, dove 1 significa che non sono affatto soddisfacenti e 5 che sono invece totalmente soddisfacenti`}
            </p>
            <Ratings onClick={(rate) => setRating(rate)}/>
            <button style={{
                padding: "8px 12px",
                borderRadius: "5px",
                backgroundColor: colors.primaryLight,
                border: "none",
                marginTop: "15px",
            }} onClick={handleRating}>{!loading ? "Invia feedback" : <Loader size={16}/>}</button>
        </div>
    )
}