import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        marginTop: "32px",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center"
    },
    postTitle: {
        fontWeight: 600,
        fontSize: "16px",
        margin: 0,
        padding: 0,
        textAlign: "left",
        marginBottom: "4px",
        color: colors.supportBlack
    },
    postTitleDisabled: {
        fontWeight: 600,
        fontSize: "16px",
        margin: 0,
        padding: 0,
        textAlign: "left",
        marginBottom: "4px",
        color: colors.supportLight
    },
    titleInput: {
        width: "100%",
        height: "46px",
        fontWeight: 400,
        fontSize: "16px",
        border: `solid 1px ${colors.supportLight}`,
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        outline: "none",
        fontFamily: "'Literata', serif",
    },
    uploadBox: {
        width: "100%",
        borderRadius: "8px",
        border: `dashed 1px ${colors.supportLight}`,
        padding: "12px 16px 12px 16px",
        display: "flex",
        justifyContent: "center",
        marginTop: "16px",
        marginBottom: "16px"
    },
    uploadedBox: {
        width: "100%",
        borderRadius: "8px",
        border: `solid 1px ${colors.primaryLight}`,
        padding: "16px",
        display: "flex",
        // justifyContent: "space-between",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: "16px"
    },
    filename: {
        fontWeight: 400,
        fontSize: "16px",
        marginLeft: "8px"
    },
    dragText: {
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack
    },
    fileTypeText: {
        fontWeight: 400,
        fontSize: "14px",
        color: colors.supportDarker
    },
    textarea: {
        width: "100%",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        boxShadow: "0px 2px 1px 0px #1018280D",
        outline: "none",
        fontFamily: "'Literata', serif",
        fontSize: "18px",
        fontWeight: "400"
    },
    bodyTitle: {
        fontWeight: 700,
        fontSize: "24px",
        margin: 0,
        padding: 0,
        color: colors.supportBlack,
        marginBottom: "16px",
        marginTop: "16px",
        textAlign: "left"
    },
    bodyTitleDisabled: {
        fontWeight: 700,
        fontSize: "24px",
        margin: 0,
        padding: 0,
        color: colors.supportLight,
        marginBottom: "16px",
        marginTop: "16px",
        textAlign: "left"
    },
    title: {
        fontWeight: 700,
        fontSize: "32px",
        color: colors.supportBlack,
        margin: 0,
        marginRight: "10px"
        // width:"170px"
    },
    button: {
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        fontFamily: "'Literata', serif",
        border: `1px solid ${colors.supportLight}`,
        borderRadius: "8px",
        boxShadow: `0px 2px 1px 0px #1018280D`,
        padding: "12px 16px 12px 16px",
        marginLeft: "8px",
        // height:"46px",
        cursor: "pointer"
    },
    buttonYellow: {
        fontWeight: 600,
        fontSize: "16px",
        // height:"46px",
        color: colors.supportBlack,
        fontFamily: "'Literata', serif",
        border: "none",
        backgroundColor: colors.primaryLight,
        borderRadius: "8px",
        boxShadow: `0px 2px 1px 0px #1018280D`,
        padding: "12px 16px 12px 16px",
        marginLeft: "8px",
        cursor: "pointer"
    },
}