import React from "react";
import {Row} from "react-bootstrap";
import {style} from "../style";
import {FileUploader} from "react-drag-drop-files";
import fileUploaded from "../../../../../../assets/png/fileUploaded.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../../../../common/colors";

type Props = {
    onFileChange: (file: File) => void,
    onTextareaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    content: string
}

export default function PostBody({onTextareaChange, onFileChange, content}: Props) {

    const [file, setFile] = React.useState<File | undefined>(undefined)

    const onFileUpdate = (file: File) => {
        setFile(file)
        onFileChange(file)
    }

    return (
        <div style={{marginRight: "5px"}}>
            <Row className={"m-0 w-100"}>
                <h2 style={style.bodyTitle}>{"Carica un documento"}</h2>
            </Row>
            <div className={"m-0 w-100 fileUploaderParent"}>
                {file === undefined ?
                    <FileUploader handleChange={onFileUpdate}
                                  label={"Trascina il tuo file qui e caricalo"} name="file"
                                  types={["docx"]}>
                        <Row className={"m-0 w-100"} style={style.uploadBox}>
                            <img style={{height: "40px", width: "auto"}} src={file} alt={""}/>
                            <div style={{marginLeft: "17px", width: "fit-content"}}>
                                <div style={style.dragText}>{"Trascina il tuo file qui e caricalo"}</div>
                                <div style={style.fileTypeText}>Formati supportati: .docx</div>
                            </div>
                        </Row>
                    </FileUploader>
                    : <div style={style.uploadedBox}>
                        <div className={"d-flex"}>
                            <img style={{height: "25px", width: "auto"}} src={fileUploaded} alt={""}/>
                            <div style={style.filename}>
                                {file.name}
                            </div>
                        </div>
                        <div style={{marginLeft: "15px"}}>
                            <FontAwesomeIcon icon={faCircleCheck} color={colors.primaryLight} size={"2x"}/>
                        </div>
                    </div>}
            </div>
            <Row className={"m-0 w-100"}>
                <h2 style={file === undefined ? style.bodyTitle : style.bodyTitleDisabled}>
                    {"o inseriscilo manualmente"}
                </h2>
            </Row>
            <Row className={"m-0 w-100"}>
                <h3 style={file === undefined ? style.postTitle : style.postTitleDisabled}>{"Contenuti dell'articolo"}</h3>
            </Row>
            <Row className={"m-0 w-100"}>
                    <textarea rows={6} style={style.textarea} value={content}
                              onChange={onTextareaChange} placeholder={"Inizia a scrivere"}
                              disabled={file !== undefined}></textarea>
            </Row>
            <Row className={"m-0 w-100"} style={{
                justifyContent: "flex-end",
                color: `${file !== undefined ? colors.supportLight : colors.supportDarker}`
            }}>
                {!(content.length > 0) ? "Minimo 2.000 caratteri" : `${content.length.toLocaleString('it') }/2.000 caratteri`}
            </Row>
        </div>
    );
}