import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        display: "flex",
        alignItems: "center",
        marginTop: "9px"
    },
    image: {
        height: "15px",
        width: "15px"
    },
    text: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "19.07px",
        color: colors.supportDarker,
        marginLeft: "10.5px"
    }
}