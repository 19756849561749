import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        height: "100%",
        // width: "400px",
        borderRight: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 3px 10px 0px #3636361C",
        position: "fixed",
        overflow: "hidden",
        left:"0",
        borderTopRightRadius:"16px",
        borderBottomRightRadius:"16px",
        backgroundColor:"white",
        transition:"left .5s",
    },
    boxAnimate: {
        height: "100%",
        // width: "400px",
        borderRight: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 3px 10px 0px #3636361C",
        position: "fixed",
        overflow: "hidden",
        left:"400px",
        borderTopRightRadius:"16px",
        borderBottomRightRadius:"16px",
        backgroundColor:"white",
        transition:"left .5s",
    },
    box2: {
        height: "100%",
        position: "fixed",
        backgroundColor: "rgba(29,29,27,0.3)",
        top: "80",
        zIndex: 50,
        overflowX: "hidden",
        display: "flex",
        flexDirection: "row"
    },
    box2Active: {
        height: "100%",
        width: "100%",
        position: "fixed",
        backgroundColor: "rgba(29,29,27,0.3)",
        top: "80",
        zIndex: 50,
        overflowX: "hidden",
        display: "flex",
        flexDirection: "row"
    },
    content: {
        width: "375px",
        height: "calc(100% - 80px)",
        backgroundColor: colors.supportWhite,
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
        overflow: "hidden"
    },
}