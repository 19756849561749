import {FilterContent} from "../../FilterByNew";
import {style} from "./style";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleRight} from "@fortawesome/free-solid-svg-icons";

type SelectableTree = {
    tree: TreeLeaf[]
}

type TreeLeaf = {
    id: number,
    name: string,
    subitems?: TreeLeaf[]
}

type Props = {
    selectable: SelectableTree,
    selected: FilterContent[],
    setSelected: (filterName: string, selectedFilters: FilterContent[]) => void,
    searchable: FilterContent[],
}
type PropsRenderer = {
    item: TreeLeaf,
    keyPrevious: string,
    onClick: (item: TreeLeaf) => void
}

function Renderer({item, keyPrevious, onClick}: PropsRenderer) {
    const [expanded, setExpanded] = React.useState<boolean>(false)

    if (item.subitems !== undefined)
        return (
            <div>
                <div style={style.treeRow}>
                    <div className={"d-flex"}>
                        <div style={style.iconBox} onClick={() => setExpanded(!expanded)}>
                            {!expanded ?
                                <FontAwesomeIcon icon={faAngleRight} style={style.iconRight}/>
                                : <FontAwesomeIcon icon={faAngleDown} style={style.iconDown}/>}
                        </div>
                        <div style={style.text} onClick={() => setExpanded(!expanded)}>{item.name}</div>
                    </div>
                    <div onClick={() => onClick(item)}>+</div>
                </div>
                {expanded ?
                    <div style={style.nestedRow}>
                        {item.subitems.map((item, index) =>
                            <Renderer key={`${keyPrevious}${index}`}
                                      keyPrevious={`${keyPrevious}${index}`}
                                      item={item}
                                      onClick={onClick}/>)}
                    </div> : null}
            </div>)
    return <div style={style.leaf} onClick={() => onClick(item)}>{item.name}
        <div style={style.plus} onClick={() => onClick(item)}>+</div>
    </div>;
}

export default function Tree({searchable, selectable, selected, setSelected}: Props) {//topic_tree

    const onClick = (selectedNew: TreeLeaf) => {
        let index = selected.findIndex((element) => element.id === selectedNew.id)
        if (index === -1) {
            setSelected("topic_tree", [...selected, selectedNew])
        }
    };

    const [searchingWord, setSearchingWord] = React.useState<string>("")
    const [indexesAssociated, setIndexesAssociated] = React.useState<number[]>([])

    const searchWord = (word: string) => {
        const regexp = new RegExp(`^(${word})|(?<=\s)(${word})`, "gi")// eslint-disable-line
        const indexes: number[] = []
        // eslint-disable-next-line array-callback-return
        searchable.map((element, index) => {
            const result = regexp.exec(element.name)
            if (result !== null && result[1] !== undefined) {
                indexes.push(index)
            }
        })
        setIndexesAssociated(indexes)
    }

    const setWord = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchingWord(event.target.value)
        searchWord(event.target.value)
    }

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{"Topic"}</h3>
            </div>
            <div style={style.inputBox}>
                <input type={"text"} style={style.input}
                       placeholder={"Digita per il completamento automatico"}
                       onChange={setWord}/>
            </div>
            {searchingWord.length > 0 ? <div style={style.searchResults}>
                    <h5 style={style.searchResultsTitle}>{`RISULTATI PER '${searchingWord}'`}</h5>
                    <div style={{overflowY: "scroll", flex: 1}}>
                        {indexesAssociated.map((number) => <div key={`foundSearch-${number}`}>
                            <div style={style.selected}
                                 onClick={() => onClick(searchable[number])}>
                                <div>{searchable[number]?.name}</div>
                                <div style={{marginLeft:"2px"}}>+</div>
                            </div>
                        </div>)}
                    </div>
                </div>
                : <div style={style.scrollManager}>
                    {selectable.tree.map((item, index) =>
                        <Renderer key={`renderer-${index}`} keyPrevious={`renderer-${index}`} item={item}
                                  onClick={onClick}/>)}
                </div>}
        </div>
    )
}
