import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        width: "100%",
        height: "calc(100% - 80px)",
        marginTop:"80px",
        position: "fixed",
        top: 0,
        backgroundColor: "rgba(29,29,27,0.3)",
        zIndex:9000

    },
    modal: {
        height: "100%",
        marginLeft: "10%",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        padding: "40px 30px 40px 38px",
        backgroundColor: colors.supportWhite,
        overflowY: "scroll"
    },
    header: {
        display:"flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        fontWeight: 700,
        fontSize: "32px",
        color: colors.supportBlack,
        margin: 0,
        width:"170px"
    },
    button: {
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        fontFamily: "'Literata', serif",
        border: `1px solid ${colors.supportLight}`,
        borderRadius: "8px",
        boxShadow: `0px 2px 1px 0px #1018280D`,
        padding: "12px 16px 12px 16px",
        marginLeft: "8px",
        // height:"46px",
        cursor: "pointer"
    },
    buttonDelete: {
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportWhite,
        backgroundColor:colors.supportDanger,
        fontFamily: "'Literata', serif",
        border: `1px solid ${colors.supportLight}`,
        borderRadius: "8px",
        boxShadow: `0px 2px 1px 0px #1018280D`,
        padding: "12px 16px 12px 16px",
        marginLeft: "8px",
        // height:"46px",
        cursor: "pointer"
    },
    buttonYellow: {
        fontWeight: 600,
        fontSize: "16px",
        // height:"46px",
        color: colors.supportBlack,
        fontFamily: "'Literata', serif",
        border: "none",
        backgroundColor: colors.primaryLight,
        borderRadius: "8px",
        boxShadow: `0px 2px 1px 0px #1018280D`,
        padding: "12px 16px 12px 16px",
        marginLeft: "8px",
        cursor: "pointer"
    },
    postTitle: {
        marginTop: "32px",
        marginBottom:"32px",
        borderRadius: "16px",
        padding: "24px",
        backgroundColor: colors.supportLighter,
    },
    postTitleTitle: {
        fontSize: "20px",
        fontWeight: 700,
        color: colors.supportBlack,
        margin: 0
    },
    expand: {
        color: colors.supportDarker,
        fontWeight: 600,
        fontSize: "16px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        display: "flex"
    },
    expandIcon: {
        marginLeft: "5px",
        width: "10px"
    },
    publication: {
        fontWeight: 400,
        fontSize: "14px",
        color: colors.supportDarker
    },
    tabs: {
        overflow: "scroll"
    },
    tabList: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
        margin:0
    },
    download: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: `1px solid ${colors.supportLight}`,
        lineHeight: "21.79px"
    },
    downloadDisabled: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportDark,
        border: `1px solid ${colors.supportLight}`,
        lineHeight: "21.79px"
    }
}