import React from "react";
import {style} from "./style";
import {FilterContent} from "../../FilterByNew";
import {Row} from "react-bootstrap";

type Props = {
    selectable: FilterContent[],
    selected: FilterContent[],
    setSelected: (filterName: string, selectedFilters: FilterContent[]) => void
}

export default function PeriodFilter({selectable, selected, setSelected}: Props) {

    const selectPeriod = (add: FilterContent) => {
        setSelected("publicationPeriods", [add])
    }

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{"Periodo di pubblicazione"}</h3>
            </div>
            <div style={style.scrollManager}>
                <div style={style.filterContent}>
                    {selectable.map((content, index) => {
                        return <Row style={style.centeredRow} key={`periodFilter-${index}`}>
                            <input
                                type={"checkbox"}
                                style={selected.length > 0 && selected[0].id === content.id ? style.checkboxSelected : style.checkbox}
                                onClick={() => selectPeriod(content)}/>
                            <span style={style.text}>{content.name}</span>
                        </Row>
                    })}
                </div>
            </div>
        </div>
    );
}