import React, {useEffect} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {style} from "../FirstLogin/style";
import LoginPointOutTitle from "../components/LoginPointOutTitle/LoginPointOutTitle";
import LoginSideSlide from "../components/LoginSideSlide/LoginSideSlide";
import Detail1 from "./Detail1/Detail1";
import CarouselIndicator from "../../CarouselIndicator/CarouselIndicator";
import Detail2 from "./Detail2/Detail2";
import Detail3 from "./Detail3/Detail3";
import {useNavigate} from "react-router-dom";
import TokenManager from "../../../common/TokenManager";
import Toast, {ApiError} from "../../../common/Toast"; // requires a loader

export default function FirstLoginCarousel() {

    const [index, setIndex] = React.useState<number>(0)
    const [firstName, setFirstName] = React.useState<string>("")
    const [company, setCompany] = React.useState<string>("")
    const [lastName, setLastName] = React.useState<string>("")
    const [jobTitle, setJobTitle] = React.useState<string>("")
    const [loading, setLoading] = React.useState<boolean>(false)
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const token = await TokenManager.getAccessToken()
            if (token === null)
                navigate("/login")
            let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!fetched.ok) {
                let data: ApiError = await fetched.json()
                Toast(data)
                return
            }
            let data: { name: string, surname: string, company_name: string } = await fetched.json()
            setFirstName(data.name)
            setLastName(data.surname)
            setCompany(data.company_name)
        }
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const completeUserInfo = async (interests: string[]) => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        const formData = new FormData();
        formData.append("job_title", jobTitle);
        formData.append("interests", interests.toString());
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/user`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        })
        if (!fetched.ok) {
            setLoading(false)
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        navigate("/discovery")
    }

    return (
        <div style={style.page}>
            <div style={style.column}>
                <div style={style.content}>
                    <div style={style.logo}>
                        <LoginPointOutTitle/>
                    </div>
                    <div style={style.slide}>
                        <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <CarouselIndicator index={index} numberOfDots={3}/>
                        </div>
                        <Carousel selectedItem={index} showArrows={false} showIndicators={false}
                                  showStatus={false}
                                  onChange={setIndex} showThumbs={false}>
                            <Detail1 nextSlide={() => setIndex(1)}/>
                            <Detail2 nextSlide={() => setIndex(2)} firstName={firstName}
                                     company={company} lastName={lastName}
                                     setJobTitle={setJobTitle}/>
                            <Detail3 onClick={completeUserInfo} loading={loading}/>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div style={style.column}>
                <LoginSideSlide title={"Lo sapevi?"}
                                text={"Copriamo più di 1.500 testate. Analizziamo i contenuti relativi a tutti i settori dell'economia, dall'ICT ai settori più analogici"}/>
            </div>
        </div>
    );
}