import React, {useEffect} from "react";
import LoginTitle from "../../components/LoginTitle/LoginTitle";
import LoginButton from "../../components/LoginButton/LoginButton";
import {style} from "./style";
import InterestCheckBox from "../../InterestCheckBox/InterestCheckBox";
import TokenManager from "../../../../common/TokenManager";
import Toast, {ApiError} from "../../../../common/Toast";
import {SelectionOptionsResponse} from "../../../home/FilterByNew/types";
import {useNavigate} from "react-router-dom";
import Loader from "../../../../common/Loader/Loader";
import {FilterContent} from "../../../home/FilterByNew/FilterByNew";

type Props = {
    onClick: (interests: string[]) => void,
    loading: boolean
}

export default function Detail3({onClick, loading}: Props) {
    const [loadingFetch, setLoadingFetch] = React.useState<boolean>(false)
    const [areas, setAreas] = React.useState<FilterContent[]>([])
    const [selectedAreas, setSelectedAreas] = React.useState<FilterContent[]>([])
    const navigate = useNavigate();

    useEffect(() => {
        getAreas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAreas = async () => {
        setLoadingFetch(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/selection-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            setLoadingFetch(false)
            Toast(data)
            return
        }
        setLoadingFetch(false)
        let data: SelectionOptionsResponse = await fetched.json()
        setAreas(data.results["thematicAreas"])
    }

    const manageArea = (selected: FilterContent) => {
        let index = selectedAreas.findIndex((area) => area.id === selected.id)
        if (index === -1) {
            let newAreas = [...selectedAreas]
            newAreas.push(selected)
            setSelectedAreas(newAreas)
        } else {
            let newAreas = [...selectedAreas]
            newAreas.splice(index, 1)
            setSelectedAreas(newAreas)
        }
    }

    return (
        <div style={style.container}>
            <div style={style.scrollManager}>
                <LoginTitle className={"mb24"} text={"Seleziona le aree di interesse professionale"}/>
                <div className={"mb32"}>
                    {loadingFetch ? <Loader/> : areas.map((area, index) =>
                        <InterestCheckBox key={`area-${index}`} handleOnChange={() => manageArea(area)} text={area.name}
                                          checked={selectedAreas.map((area) => area.name).includes(area.name)}/>)}
                </div>
                <LoginButton className={"mb32"} text={"Finisci"} loading={loading}
                             onClick={() => onClick(selectedAreas.map((area) => area.name))}/>
            </div>
        </div>
    );
}