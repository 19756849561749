import {colors} from "../../common/colors";
import CSS from "csstype";

export const style: { [key: string]: CSS.Properties } = {
    activePrimary: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.primaryLight,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: "none",
        lineHeight: "21.79px"
    },
    disabledPrimary: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.primaryLightOpacity,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: "none",
        lineHeight: "21.79px"
    },
    activeSecondary: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: `1px solid ${colors.supportLight}`,
        lineHeight: "21.79px"
    },
    disabledSecondary: {
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportDark,
        border: `1px solid ${colors.supportLight}`,
        lineHeight: "21.79px"
    }
}