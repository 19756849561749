import React, {useEffect} from "react";
import {style} from "./style";
import CheckboxFilter from "../../../../CheckboxFilter/CheckboxFilter";
import {FilterContent} from "../../FilterByNew";

type Props = {
    title: string,
    filterName: string,
    selectable: FilterContent[],
    selected: FilterContent[],
    setSelected: (filterName: string, selectedFilters: FilterContent[]) => void
}

export default function MultipleSelect({title, filterName, selectable, selected, setSelected}: Props) {

    const [checked, setChecked] = React.useState <FilterContent[]>([])

    useEffect(() => {
        setChecked([...selected])
    }, [selected])

    const handleOnChange = (selected: FilterContent) => {

        let index = checked.findIndex((element) => element.id === selected.id)
        if (index !== -1) {
            const checkedCopy = [...checked]
            checkedCopy.splice(index, 1);
            setChecked(checkedCopy)
            setSelected(filterName, checkedCopy)
        } else {
            setChecked([...checked, selected])
            setSelected(filterName, [...checked, selected])
        }
    };

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{title}</h3>
            </div>
            <div style={style.scrollManager}>
                {selectable.map((content, index) => {
                    return <CheckboxFilter key={index + "cat"} category={content.name}
                                           checked={checked.map((object) => object.name).includes(content.name)}
                                           handleOnChange={() => handleOnChange(content)}/>
                })}
            </div>
        </div>
    );
}