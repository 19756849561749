import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faFilterCircleXmark, faSearch, faXmark} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../common/colors";
import TokenManager from "../../../common/TokenManager";
import Toast, {ApiError} from "../../../common/Toast";
import {useNavigate} from "react-router-dom";
import {AuthorsResults, SourcesResults} from "../discovery/type";
import {FilterObject, Filters, SelectionOptionsResponse} from "./types";
import {style} from "./style";
import FilterBySecondTab from "./secondTab/FilterBySecondTab";
import MultipleSelect from "./FilterTypes/MultipleSelect/MultipleSelect";
import SearchAuto from "./FilterTypes/SearchAuto/SearchAuto";
import GeoFilter from "./FilterTypes/GeoFilter/GeoFilter";
import PeriodFilter from "./FilterTypes/PeriodFilter/PeriodFilter";
import Tree from "./FilterTypes/Tree/Tree";
import SaveFilters from "./SaveFilters/SaveFilters";
import SavedFilters from "./SavedFilters/SavedFilters";

export type FilterContent = (FilterObject & {
    type?: string,
    subname?: string,
    sources?: string
    associatedSources?: { id: number, name: string, role: string, type: string }[]
})

type Props = {
    setActiveFilters: React.Dispatch<Filters>,
    loading: boolean,
    addAuthor?: undefined | AuthorsResults,
    addTitle?: undefined | SourcesResults,
    discovery?: boolean
}

export default function FilterByNew(
    {
        setActiveFilters,
        loading,
        discovery = true,
        addAuthor = undefined,
        addTitle = undefined
    }: Props) {
    const [viewFilter, setViewFilter] = React.useState<string>()
    const [filters, setFilters] = React.useState<SelectionOptionsResponse>()
    const [selectedFilters, setSelectedFilters] = React.useState<Filters>({
        thematicAreas: [],
        topic_tree: [],
        mediaTypes: [],
        authors: [],
        geoCategories: [],
        entities: [],
        sources: [],
        sourceProfiles: [],
        publicationPeriods: []
    })
    const [searchedFilters, setSearchedFilters] = React.useState<Filters>({
        thematicAreas: [],
        topic_tree: [],
        mediaTypes: [],
        authors: [],
        geoCategories: [],
        entities: [],
        sources: [],
        sourceProfiles: [],
        publicationPeriods: []
    })
    const [saveFiltersVisibility, setSaveFiltersVisibility] = React.useState<boolean>(false)
    const [savedFiltersVisibility, setSavedFiltersVisibility] = React.useState<boolean>(false)
    const [activeFilter, setActiveFilter] = React.useState<boolean>(false)
    const [isGeoLocale, setIsGeoLocale] = React.useState<boolean>(false)
    const navigate = useNavigate();

    const areFiltersActive = (): boolean => {
        let active = false
        for (const filterName of Object.keys(selectedFilters)) {
            if (filterName !== "publicationPeriods") {
                if (selectedFilters[filterName].length > 0) {
                    active = true
                    break
                }
            } else {
                if (selectedFilters[filterName].length > 0 && selectedFilters[filterName][0].name !== "Ultimi sei mesi") {
                    active = true
                    break
                } else if (selectedFilters["geoCategories"].length === 0 && isGeoLocale) {
                    active = true
                    break
                }
            }
        }
        return active
    }

    const search = () => {
        let newSelectedFilters = structuredClone(selectedFilters)
        // let periodCopy = newSelectedFilters["publicationPeriods"]
        // delete newSelectedFilters['publicationPeriods'];
        // newSelectedFilters["publicationPeriod"] = periodCopy
        if (isGeoLocale && newSelectedFilters['geoCategories'].length === 0 && filters) {
            const newActiveFilters = structuredClone(selectedFilters)
            const localGeo = filters.results["geoCategories"].filter((filter) => filter.name === "Locale")
            newActiveFilters["geoCategories"] = localGeo
            newSelectedFilters["geoCategories"] = localGeo
            setSelectedFilters(newActiveFilters)
        }
        setSearchedFilters(structuredClone(newSelectedFilters))
        setActiveFilters(newSelectedFilters)
        goBackFilter()
    }

    const setActiveFiltersLocal = (filterName: string, newSelectedFilters: FilterContent[]) => {
        const newActiveFilters = structuredClone(selectedFilters)
        newActiveFilters[filterName] = newSelectedFilters
        setSelectedFilters(newActiveFilters)
    }

    const removeAFilter = (filterName: string, filterToRemove: FilterContent) => {
        if (filterName !== "publicationPeriods") {
            const index = selectedFilters[filterName].findIndex((element) => element.id === filterToRemove.id)
            if (index !== -1) {
                const newActiveFilters = structuredClone(selectedFilters)
                newActiveFilters[filterName].splice(index, 1)
                setSelectedFilters(newActiveFilters)
            }
        }
    }

    const resetFilters = () => {
        if (filters !== undefined) {
            let periodIndex = filters.results["publicationPeriods"].findIndex((period) => period.name === "Ultimi sei mesi")
            if (periodIndex !== -1)
                setSelectedFilters({
                    thematicAreas: [],
                    topic_tree: [],
                    mediaTypes: [],
                    authors: [],
                    geoCategories: [],
                    entities: [],
                    sources: [],
                    sourceProfiles: [],
                    publicationPeriods: [filters.results["publicationPeriods"][periodIndex]],
                })
            if (areFiltersActive() || (JSON.stringify(selectedFilters) !== JSON.stringify(searchedFilters) && (activeFilter || savedFiltersVisibility)))
                setActiveFilters({
                    thematicAreas: [],
                    topic_tree: [],
                    mediaTypes: [],
                    authors: [],
                    geoCategories: [],
                    entities: [],
                    sources: [],
                    sourceProfiles: [],
                    publicationPeriod: [filters.results["publicationPeriods"][periodIndex]],
                })
        }
        setIsGeoLocale(false)
        goBackFilter()
    }

    const getFilters = async () => {
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/selection-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'clientId': `0`,
            }
        })
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        let data: SelectionOptionsResponse = await fetched.json()
        setFilters(data)
        let periodIndex = data.results["publicationPeriods"].findIndex((period) => period.name === "Ultimi sei mesi")
        if (periodIndex !== -1) {
            const newActiveFilters = structuredClone(selectedFilters)
            newActiveFilters["publicationPeriods"] = [data.results["publicationPeriods"][periodIndex]]
            setSelectedFilters(newActiveFilters)
        }
    }

    useEffect(() => {
        if (addAuthor !== undefined && filters !== undefined) {
            const authors = filters.results["authors"]
            let index = authors.findIndex((author) => author.id === addAuthor.author.id)
            let indexPresent = selectedFilters["authors"].findIndex((author) => author.id === addAuthor.author.id)
            if (index !== -1 && indexPresent === -1) {
                const newActiveFilters = structuredClone(selectedFilters)
                newActiveFilters["authors"].push(filters.results["authors"][index])
                setSelectedFilters(newActiveFilters)
                setActiveFilter(true)
                setViewFilter("authors")
            }
        }
        if (addTitle !== undefined && filters !== undefined) {
            const sources = filters.results["sources"]
            let index = sources.findIndex((source) => source.name === addTitle.source.name)
            let indexPresent = selectedFilters["sources"].findIndex((source) => source.id === addTitle.source.id)
            if (index !== -1 && indexPresent === -1) {
                const newActiveFilters = structuredClone(selectedFilters)
                newActiveFilters["sources"].push(filters.results["sources"][index])
                setSelectedFilters(newActiveFilters)
                setActiveFilter(true)
                setViewFilter("sources")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addAuthor, addTitle])

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const goBackFilter = () => {
        setActiveFilter(false)
        setTimeout(() => {
            setViewFilter(undefined)
        }, 500)
    }

    const stopModifyingFilters = () => {
        if (JSON.stringify(selectedFilters) !== JSON.stringify(searchedFilters)) {
            setSelectedFilters(structuredClone(searchedFilters))
        }
        setActiveFilter(false)
        setSavedFiltersVisibility(false)
    }

    return (
        <>
            {saveFiltersVisibility ?
                <SaveFilters filters={selectedFilters} discovery={discovery}
                             onClose={() => setSaveFiltersVisibility(false)}/> : null}
            <FilterBySecondTab active={activeFilter || savedFiltersVisibility} onStop={stopModifyingFilters}>
                {viewFilter ?
                    <>
                        {["mediaTypes", "sourceProfiles"].includes(viewFilter) ?
                            <MultipleSelect
                                title={filterTranslation[viewFilter]}
                                filterName={viewFilter}
                                // @ts-ignore
                                selectable={filters?.results[viewFilter]}
                                setSelected={setActiveFiltersLocal}
                                selected={selectedFilters[viewFilter]}/>
                            : null}
                        {["topics", "entities", "authors", "sources"].includes(viewFilter) ?
                            <SearchAuto
                                title={filterTranslation[viewFilter]}
                                filterName={viewFilter}
                                // @ts-ignore
                                selectable={filters?.results[viewFilter]}
                                setSelected={setActiveFiltersLocal}
                                selected={selectedFilters[viewFilter]}/>
                            : null}
                        {viewFilter === "geoCategories" ?
                            <GeoFilter
                                // @ts-ignore
                                selectable={filters?.results[viewFilter]}
                                setSelected={setActiveFiltersLocal}
                                selected={selectedFilters[viewFilter]}
                                setIsLocale={setIsGeoLocale}/>
                            : null}
                        {viewFilter === "publicationPeriods" ?
                            <PeriodFilter
                                // @ts-ignore
                                selectable={filters?.results["publicationPeriods"]}
                                setSelected={setActiveFiltersLocal}
                                selected={selectedFilters["publicationPeriods"]}/>
                            : null}
                        {viewFilter === "topic_tree" ?
                            <Tree
                                // @ts-ignore
                                selectable={filters?.results["topic_tree"]}
                                setSelected={setActiveFiltersLocal}
                                selected={selectedFilters["topic_tree"]}
                                // @ts-ignore
                                searchable={filters?.results["topics"]}/>
                            : null}
                    </>
                    : null}
                {savedFiltersVisibility ?
                    <SavedFilters goBack={() => setSavedFiltersVisibility(false)}
                                  setSelectedFilters={setSelectedFilters} discovery={discovery}/>
                    : null}
            </FilterBySecondTab>
            <div style={style.box}>
                <div style={style.content}>
                    <div style={style.header}>
                        <h5 style={style.title}>{"Filtra per"}</h5>
                        {activeFilter || savedFiltersVisibility || areFiltersActive() ? <div className={"d-flex"}>
                                <div style={style.resetFilters} onClick={resetFilters}>
                                    <FontAwesomeIcon icon={faFilterCircleXmark} color={"#000"}/>
                                </div>
                            </div>
                            : <button style={style.loadSave} onClick={() => setSavedFiltersVisibility(true)}>
                                {"Carica i filtri salvati"}
                            </button>}
                    </div>
                    {filters?.results !== undefined ?
                        <div style={{marginBottom: " 100px", paddingTop: "20px"}}>
                            <table style={style.table}>
                                {Object.keys(filters.results).map((filterName, index) => {
                                    if (["topics", "thematicAreas"].includes(filterName))
                                        return null
                                    if ((!discovery && ["topic_tree", "thematicAreas"].includes(filterName)) ||
                                        (discovery && ["publicationPeriods"].includes(filterName)))
                                        return null
                                    return (
                                        <tbody key={index}>
                                        <tr style={selectedFilters[filterName]?.length > 0 ?
                                            activeFilter && viewFilter === filterName ? style.filterRowWithFiltersActive : style.filterRowWithFilters
                                            : activeFilter && viewFilter === filterName ? style.filterRowActive : style.filterRow}
                                            onClick={() => {
                                                setActiveFilter(true)
                                                setViewFilter(filterName)
                                                setSavedFiltersVisibility(false)
                                            }}>
                                            <td style={style.filterName}>{filterTranslation[filterName]}</td>
                                            <td className={"expandColumn"}>
                                                <button style={style.viewAllButton} className={"expand"}>
                                                    {/*{"Espandi "}*/}
                                                    <FontAwesomeIcon icon={faAngleRight} color={colors.supportDarker}/>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} style={style.activeFilterRow}>
                                                {selectedFilters[filterName].map((object, index) =>
                                                    <div key={`${object.name}-${index}`} style={style.activeFilter}>
                                                        <div className={"d-flex flex-column"}>
                                                            <span>{filterName === "geoCategories" && object.name !== "Nazionale" ? object.subname : object.name}</span>
                                                            {filterName === "authors" ?
                                                                object.sources?.split(",").map((source, index) => {
                                                                    if (object.associatedSources) {
                                                                        return <span style={style.authorSpan}
                                                                                     key={`associatedSources-${index}`}>
                                                                            {source}{`, ${object.associatedSources[index].type}`}
                                                                        </span>
                                                                    }
                                                                    return null
                                                                })
                                                                : null}
                                                            {filterName === "entities" ?
                                                                <span style={style.authorSpan}>
                                                                    {object.type}
                                                                </span>
                                                                : null}
                                                            {filterName === "sources" ?
                                                                <span style={style.authorSpan}>
                                                                    {object.type}
                                                                </span>
                                                                : null}
                                                        </div>
                                                        {filterName !== "publicationPeriods" && activeFilter ? <div>
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{cursor: "pointer", marginLeft: "10px"}}
                                                                color={colors.supportDarker}
                                                                onClick={() => removeAFilter(filterName, object)}/>
                                                        </div> : null}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        </tbody>
                                    )
                                })}
                            </table>
                            {areFiltersActive() || (JSON.stringify(selectedFilters) !== JSON.stringify(searchedFilters) && (activeFilter || savedFiltersVisibility)) ?
                                <div className={"w-100"}>
                                    <button style={style.saveFilters} onClick={() => setSaveFiltersVisibility(true)}>
                                        {"Salva filtri"}
                                    </button>
                                    <button style={loading ? style.searchButtonDisabled : style.searchButton}
                                            onClick={search} disabled={loading}>
                                        {"Avvia la ricerca"}
                                        <FontAwesomeIcon icon={faSearch} color={"#000"} style={{marginLeft: "4px"}}
                                                         size={"sm"}/>
                                    </button>
                                </div> : null}
                        </div>
                        : null}
                </div>
            </div>
        </>
    );
}

export const filterTranslation: { [key: string]: string } = {
    thematicAreas: "Aree tematiche",
    topics: "Topic",
    mediaTypes: "Tipologia di media",
    authors: "Autori",
    geoCategories: "Copertura geografica",
    entities: "Organizzazioni, persone, luoghi",
    sources: "Testate",
    sourceProfiles: "Profilo della testata",
    publicationPeriods: "Periodo di pubblicazione",
    topic_tree: "Topic",
}// entities: "Parole chiave",