import filterIcon from "../../../../../assets/svg/Filter.svg"
import {style} from "./style";
import React from "react";

type Props = {
    onClick: () => void
}

export default function AddToFilters({onClick}: Props) {
    const [hover, setHover] = React.useState<boolean>(false)

    return (
        <div style={style.outerBox} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div style={hover ? style.boxHovered : style.box} onClick={onClick}>
                <img src={filterIcon} style={style.icon} alt={""}/>
                <span style={hover ? style.textHovered : style.text}>{"Aggiungi ai filtri"}</span>
            </div>
        </div>
    )
}