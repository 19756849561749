import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceFrown} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../common/colors";
import {style} from "./style";
import React from "react";

type Props = {
    text: string
}
export default function NoResults({text}: Props) {
    return (
        <div className={"d-flex justify-content-center align-items-center flex-column mt-5"}>
            <FontAwesomeIcon icon={faFaceFrown} style={{height: "80px"}} size={"2xl"}
                             color={colors.primaryLight}/>
            <p style={style.noResult}>{text}</p>
        </div>
    )
}