import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    header: {
        display: "flex",
        flexDirection: "row",
        fontSize: "24px",
        fontWeight: "700",
        alignItems: "center",
        padding: "32px",
    },
    content: {
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    scrollManager: {
        height: "calc(100% - 132px)",
        width: "100%",
        overflow: "auto",
        boxSizing: "unset",
        paddingRight: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "700",
        // marginLeft: "20px",
        marginBottom: 0
    },
    selectable: {
        paddingLeft: "32px",
        paddingRight: "32px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "12px"
    },
    checkBox: {
        height: "25px",
        width: "25px",
        borderRadius: "8px",
        accentColor: colors.primaryLight,
        marginRight: "16px"
    }
}