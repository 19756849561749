import React, {useState} from "react";
import {Row} from "react-bootstrap";
import {style} from "./style";
import TokenManager from "../../../../common/TokenManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {colors} from "../../../../common/colors";
import Loader from "../../../../common/Loader/Loader";

type Props = {
    id: string,
    title: string,
    content: string,
    date: string,
    category: string,
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
    setRefresh: () => void
}
export default function Post({title, content, id, category, setRefresh, date, onClick}: Props) {
    const newDate = new Date(date)
    let parsedDate = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`
    const [hover, setHover] = useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    const deletePost = async (e: React.MouseEvent) => {
        e.stopPropagation()
        if (confirmDelete) {
            setDeleting(true)
            const token = await TokenManager.getAccessToken()
            await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    key: `post-${id}`,
                    value: null,
                    tag: "post"
                })
            })
            await fetch(`${process.env.REACT_APP_BASE_URL_ANALYTICS}/smart-targeting/document/?documentId=${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'clientId': `0`,
                }
            })
            setConfirmDelete(false)
            setDeleting(false)
            setHover(false)
            setRefresh()
        } else {
            setConfirmDelete(true)
        }
    }

    return (
        <div style={style.box} onClick={(e) => onClick(e)}>
            <Row>
                <h4 style={style.title}>{title}</h4>
            </Row>
            <Row style={style.contentText}>
                <p>{`${content.substring(0, 1000)}${content.length > 500 ? "..." : ""}`}</p>
            </Row>
            <div className={"d-flex flex-row justify-content-between"}>
                <span style={style.date}>{parsedDate}</span>
                {/*<span style={style.category}>{this.props.category}</span>*/}
                {deleting ? <div className={"w-2"}><Loader size={24}/></div>
                    : <div style={style.outerBox} onMouseEnter={() => setHover(true)}
                           onMouseLeave={() => setHover(confirmDelete)}>
                        <div style={hover ? confirmDelete ? style.deleteClicked : style.deleteHovered : style.delete}
                             onClick={(e) => deletePost(e)}>
                            <FontAwesomeIcon icon={faTrash}
                                             color={confirmDelete ? colors.supportWhite : colors.supportBlack}
                                             size={"1x"}/>
                            <span
                                style={hover ? confirmDelete ? style.textClicked : style.textHovered : style.text}>{confirmDelete ? "Conferma eliminazione" : "Elimina"}</span>
                        </div>
                    </div>}
            </div>
        </div>
    );
}