import {Filters} from "./types";

export function FilterConverter(filters: Filters): string {
    let filtersConverted = ""
    for (const key of Object.keys(filters)) {
        if (filters[key].length > 0) {
            let parsedKey = key
            if (key === "topic_tree")
                parsedKey = "topics"
            else if (key === "publicationPeriods")
                parsedKey = "publicationPeriod"
            if (filtersConverted === "") {
                filtersConverted += `${parsedKey}=`
            } else {
                filtersConverted += `&${parsedKey}=`
            }
            for (let i = 0; i < filters[key].length; i++) {
                if (i + 1 === filters[key].length) {
                    filtersConverted += filters[key][i].id
                } else {
                    filtersConverted += filters[key][i].id + ","
                }
            }
        }
    }
    return filtersConverted
}