import React, {useEffect} from "react";
import {Row} from "react-bootstrap";
import {style} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightAndDownLeftFromCenter} from "@fortawesome/free-solid-svg-icons";
import {ContentsResult} from "../../type";
import pc from "../../../../../assets/png/pc.png";
import newspaper from "../../../../../assets/png/newspaper.png";

type Props = {
    result: ContentsResult,
    discovery?: boolean
    onClick?: React.Dispatch<any>
}

export default function ContentResults({result, discovery = false, onClick}: Props) {
    const [authors, setAuthors] = React.useState<string>("")
    useEffect(() => {
        let authors = ""
        if (result.authors !== null) {
            for (let i = result.authors.length - 1; 0 <= i; i--) {
                authors = ""
                switch (i) {
                    case 0:
                        authors += `${result.authors[i].name} `//todo
                        break
                    case 1:
                        authors += `${result.authors[i].name} e `
                        break
                    default:
                        authors += `${result.authors[i].name}, `
                        break
                }
            }
        }
        setAuthors(authors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={style.box}>
            <div style={{display: "flex", justifyContent: "space-between",}}>
                <div className={"d-flex"}>
                    <div style={style.icon}>
                        {result.mediaType.name === "Stampa digitale" ?
                            <img src={pc} alt={""} style={{width: "18px",height:"auto"}}/>
                            : <img src={newspaper} alt={""} style={{width: "18px",height:"auto"}}/>}
                    </div>
                    <div className={"flex-column"} style={{marginTop: "-9px"}}>
                        <a rel={"noreferrer"} style={style.title} href={result.url ? result.url : undefined} target="_blank">{result.title}</a>
                        <Row style={{paddingRight: "12px", display: "block", marginBottom: "16px",marginTop: "8px"}}>
                            <span style={style.mediaType}>{result.mediaType.name}</span>
                            <span style={style.titleType}>{result.titleType.name}</span>
                            {result.topics?.length > 0 ?
                                result.topics.map((topic, index) =>
                                    <span key={`topic-${result.contentId}-${index}`} style={style.topic}>{topic}</span>)
                                : null}
                        </Row>
                    </div>
                </div>
                {result.snippet.length > 500 ?
                    <FontAwesomeIcon style={{cursor: "pointer"}} icon={faUpRightAndDownLeftFromCenter}
                                     onClick={onClick}/> : null}
            </div>
            <Row>
                <p style={style.content}>{`${result.snippet.substring(0, 500)}${result.snippet.length > 503 ? "..." : ""}`}</p>
            </Row>
            <Row>
                <p style={style.footer}>
                    {result.authors?.length > 0 ?
                        <>{"di "}<span style={style.bold}>
                            {authors}
                        </span>
                        </>
                        : null}
                    {result.source?.name ? <>
                            {"in "}
                            <span style={style.bold}>
                            {`${result.source.name} `}
                        </span></>
                        : null}
                    {"il "}
                    <span style={style.bold}>
                        {result.publicationDate}
                    </span>{/*todo dynamic date*/}
                </p>
            </Row>
        </div>
    );
}