import React, {useEffect, useState} from "react";
import {style} from "./style";
import LoginPointOutTitle from "../components/LoginPointOutTitle/LoginPointOutTitle";
import LoginTitle from "../components/LoginTitle/LoginTitle";
import LoginButton from "../components/LoginButton/LoginButton";
import LoginSideSlide from "../components/LoginSideSlide/LoginSideSlide";
import LoginSubtitle from "../components/LoginSubtitle/LoginSubtitle";
import LoginTwoFactorInput from "../components/LoginTwoFactorInput/LoginTwoFactorInput";
import {useLocation, useNavigate} from "react-router-dom";
import TokenManager from "../../../common/TokenManager";
import Toast, {ApiError} from "../../../common/Toast";

export default function FirstLogin() {
    const [code, setCode] = useState<string>("")
    const [validation, setValidation] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)
    const {state}: { state: { password_change_needed: boolean } } = useLocation();
    const navigate = useNavigate();
    const [ready, setReady] = React.useState<boolean>(false)

    useEffect(() => {
        if (validation !== "") return;
        const token_1f = TokenManager.getFactorToken(1)
        const token_2f = TokenManager.getFactorToken(2)
        if (token_1f === null) {
            navigate("/login")
            return
        }
        if (token_2f !== null) {
            navigate("/change-password", {
                state: state
            })
            return
        } else {
            setReady(true)
        }
        sendEmail().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation])

    const sendEmail = async () => {
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login/email`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${TokenManager.getFactorToken(1)}`,
                'Content-type': "application/json"
            }
        })
        setLoading(false)
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        let data: { validationToken: string } = await fetched.json()
        setValidation(data.validationToken)
    }

    const loginOtp = async () => {
        setLoading(true)
        if (validation.length === 0)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                validationToken: validation,
                otp: code
            })
        })
        setLoading(false)
        if (!fetched.ok) {
            let data: ApiError = await fetched.json()
            Toast(data)
            return
        }
        let data: { token_2f: string } = await fetched.json()
        TokenManager.setFactorToken(data.token_2f, 2)
        navigate('/change-password', {state: state})
    }

    return (
        <div style={style.page}>
            <div style={style.column}>
                {ready ? <div style={style.content}>
                    <div style={style.logo}>
                        <LoginPointOutTitle/>
                    </div>
                    <div>
                        <LoginTitle className={"mb32"} text={"Aspetta! Eseguiamo un controllo di sicurezza"}/>
                        <LoginSubtitle className={"mb32"}
                                       text={"Non riconosciamo questo dispositivo. Come misura di sicurezza, abbiamo inviato un codice di 6 cifre al tuo indirizzo e-mail. Inseriscilo qui di seguito, per favore"}/>
                        <LoginTwoFactorInput className={"mb32"} setCode={setCode}/>
                        <LoginButton className={"mb32"} text={"Login"}
                                     onClick={loginOtp} loading={loading}/>
                    </div>
                </div> : null}
            </div>
            <div style={style.column}>
                <LoginSideSlide title={"Lo sapevi?"}
                                text={"Copriamo più di 1.500 testate. Analizziamo i contenuti relativi a tutti i settori dell'economia, dall'ICT ai settori più analogici"}/>
            </div>
        </div>);
}