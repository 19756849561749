import React from "react";
import background from "../../../../assets/svg/LogoLine.svg"
import {style} from "./style";

type Props = {
    className?: string
}
export default class LoginPointOutTitle extends React.Component<Props, any> {
    render() {
        return (
            <div className={this.props.className} style={style.box}>
                <img src={background} alt={""}/>
                <h3 style={style.title}>Point-out</h3>
            </div>
        );
    }
}