import CSS from "csstype";
import {colors} from "../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        display: "flex",
        flexDirection: "row",
    },
    indicator: {
        width: "11px",
        height: "11px",
        backgroundColor: colors.supportLight,
        margin: "3px",
        borderRadius:"10px"
    },
    indicatorSelected: {
        width: "11px",
        height: "11px",
        backgroundColor: colors.primaryLight,
        margin: "3px",
        borderRadius:"10px"
    },
}