import React from "react";
import {style} from "../../../loginForm/components/LoginPointOutTitle/style";
import background from "../../../../assets/svg/Vector.svg";

export default class LogoHeader extends React.Component<any, any> {
    render() {
        return (
            <div className={this.props.className} style={style.box}>
                <img src={background} alt={""}/>
                <h3 style={style.title}>Point-out</h3>
            </div>
        );
    }
}