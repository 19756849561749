import React from "react";
import {style} from "./style";

type Props = {
    text: string,
    className?: string
}

export default class LoginTitle extends React.Component<Props, any> {
    render() {
        return (
            <h1 className={this.props.className} style={style}>{this.props.text}</h1>
        );
    }
}