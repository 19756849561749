import CSS from "csstype";
import {colors} from "../../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        border: `solid 1px ${colors.supportLight}`,
        borderRadius: "16px",
        padding: "24px",
        boxShadow: "0px 3px 10px 0px #3636361C",
        transition: "border-radius 1s",
    },
    boxNoBBorderRadius: {
        border: `solid 1px ${colors.supportLight}`,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px",
        padding: "24px",
        transition: "border-radius 1s",
    },
    boxRedazione: {
        // maxHeight:"300px",
        // overflowY: "auto",
        // border: `solid 1px ${colors.supportLight}`,
        // padding: "24px",
        boxShadow: "0px 3px 10px 0px #3636361C",
        border: `solid 0px ${colors.supportLight}`,
        // borderBottomRightRadius: "16px",
        // borderBottomLeftRadius: "16px",
        // borderTopLeftRadius: 0,
        // borderTopRightRadius: 0,
        borderRadius: "16px",
        maxHeight: "0px",
        padding: "0 24px 0 24px",
        overflow: "hidden",
        transition: "max-height 1s,padding 1s,border-radius 1s,border 1s,box-shadow 1s"
    },
    boxRedazioneT: {
        // maxHeight:"300px",
        // overflowY: "auto",
        overflow: "hidden",
        border: `solid 1px ${colors.supportLight}`,
        padding: "24px",
        borderTop: 0,
        boxShadow: "0px 3px 10px 0px #3636361C",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: "16px",
        borderBottomLeftRadius: "16px",
        maxHeight: "400px",
        transition: "max-height 1s,padding 1s,border-radius 1s,box-shadow 1s"
    },
    mb24: {
        marginBottom: "24px"
    },
    header: {
        // alignItems: "center",
        flexWrap: "unset",
        display: "flex",
        marginBottom: "6px"
    },
    icon: {
        backgroundColor: colors.primaryLight,
        borderRadius: "20px",
        height: "40px",
        width: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
    },
    title: {
        fontSize: "20px",
        fontWeight: 700,
        margin: 0,
        marginRight: "8px",
        marginTop: "-6px",
        color: colors.supportBlack
    },
    image: {
        height: "18px",
        width: "auto",
    },
    stonks: {
        height: "13px",
        width: "auto",
    },
    imageLeft: {
        height: "18px",
        width: "auto",
        marginLeft: "50px"
    },
    infoText: {
        fontSize: "14px",
        fontWeight: 400,
        // marginLeft: "10px",
        color: colors.supportBlack,
        display: "contents"
    },
    rightImage: {
        width: "18px",
        height: "auto"
    },
    rightInfoText: {
        fontSize: "14px",
        fontWeight: 400,
        margin: 0,
        width: "calc(100% - 24px)",
        // marginLeft: "10px",
        color: colors.supportBlack,
        overflowWrap: "break-word",
    },
    imageContainer: {
        display: "flex",
        width: "20px",
        justifyContent: "center",

    },
    infoRow: {
        alignItems: "center",
        marginBottom: "9px",
        flexWrap: "unset",
    },
    redazioneButton: {
        alignItems: "flex-end",
        paddingRight: "12px",
        flexWrap: "unset",
        display: "flex",
        flex: 1,
        justifyContent: "flex-end"
    },
    leftRow: {
        flexWrap: "unset",
        alignItems: "center"
    },
    lastColumn: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    download: {
        cursor: "pointer",
        padding: "10px 16px 10px 16px",
        backgroundColor: colors.primaryLight,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: 600,
        fontSize: "16px",
        color: colors.supportBlack,
        border: "none",
        lineHeight: "21.79px",
        width: "fit-content"
    },
    // table:{
    //     width: "100%",
    //     borderSpacing: "1",
    //     borderCollapse: "collapse",
    //     marginBottom: "24px",
    //     backgroundColor:colors.primaryLight
    // },
    table: {
        minWidth: "100%",
        // backgroundColor: colors.primaryLight,
        borderCollapse: "collapse",
        border: "2px solid white",
        letterSpacing: "1px",
        fontSize: "0.8rem",
    },
    thead: {
        border: "3px solid white",
        padding: "5px 5px",
        fontWeight: "bold",
        backgroundColor: colors.primaryLight,
    },
    downloadButton: {
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        flexWrap: "unset",
        justifyContent: "flex-end",
        paddingTop: "10px"
    },
    trow: {
        border: "3px solid white",
        // padding: "5px 5px",
        fontWeight: "normal",
        whiteSpace: "nowrap"
    }
}