import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: CSS.Properties = {
    fontWeight: 700,
    color: colors.supportBlack,
    fontSize: "32px",
    lineHeight: "47.52px",
    fontFamily: "'Literata', serif",
    margin: 0
}