import {style} from "./style";
import React, {useEffect} from "react";
import TokenManager from "../../../../common/TokenManager";
import {useNavigate} from "react-router-dom";
import {SettingType} from "../../smartTargeting/SmartTrgeting";
import Loader from "../../../../common/Loader/Loader";
import {Filters} from "../types";
import Toast, {ApiError} from "../../../../common/Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

type Props = {
    goBack: () => void,
    setSelectedFilters: (filters: Filters) => void,
    discovery?: boolean
}
export default function SavedFilters({goBack, setSelectedFilters, discovery = true}: Props) {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [filters, setFilters] = React.useState<(SettingType & {
        value: {
            name: string,
            filters: Filters
        }
    })[]>([])
    const navigate = useNavigate();

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFilters = async () => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/setting/?tag=saved-filter-${discovery ? "discovery" : "media-analytics"}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        if (!fetched.ok) {
            setLoading(false)
            return
        }
        setLoading(false)
        let data: (SettingType & {
            value: {
                name: string,
                filters: Filters
            }
        })[] = await fetched.json()
        setFilters(data)
    }

    const selectFilter = (filters: Filters) => {
        setSelectedFilters(filters)
        goBack()
    }

    const deleteFilter = async (key: string) => {
        setLoading(true)
        const token = await TokenManager.getAccessToken()
        if (token === null)
            navigate("/login")
        let fetched = await fetch(`${process.env.REACT_APP_BASE_URL}/setting`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                key: key,
                value: null,
                tag: `deleted-filter`
            })
        })
        if (!fetched.ok) {
            setLoading(false)
            const error: ApiError = await fetched.json()
            Toast(error)
            return
        }
        getFilters()
    }

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{"Carica i filtri salvati"}</h3>
            </div>
            <div style={style.filters}>
                {loading ? <Loader/> : filters.map((filter, index) =>
                    <div key={`filter-${index}`} style={style.filter}>
                        <span onClick={() => {
                            selectFilter(filter.value.filters)
                        }} style={{
                            padding: "12px 16px",
                            flexGrow: "1"
                        }}>{filter.value.name}</span>
                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteFilter(filter.key)} style={{
                            padding: "12px 16px"
                        }}/>
                    </div>)}
            </div>
        </div>
    )
}