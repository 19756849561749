import React from "react";
import {style} from "./style";
import "./cssStyle.css"

type Props = {
    type: React.HTMLInputTypeAttribute,
    placeholder: string | undefined,
    setValue: (e: React.ChangeEvent<HTMLInputElement>) => void,
    // value: string | number | readonly string[] | undefined,
    className?: string
    value?: string,
    disabled?: boolean
}

export default class LoginInput extends React.Component<Props, any> {
    render() {
        return (
            <input className={`loginInput ${this.props.className}`} type={this.props.type} style={style}
                   placeholder={this.props.placeholder} onChange={(event) => this.props.setValue(event)}
                   value={this.props.value} disabled={this.props.disabled}/>
        )
    }
}