import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        width: "calc(100% - 64px)",
        marginLeft: "32px",
        marginBottom: "24px",
        marginTop: 0,
        boxShadow: "0px 3px 10px 0px #3636361C",
        borderRadius: "8px",
        border: `solid 1px ${colors.supportLight}`,
        padding: "24px",
        cursor: "pointer"
    },
    title: {
        margin: 0,
        fontWeight: 700,
        fontSize: "20px",
        color: colors.supportBlack,
        marginBottom: "16px"
    },
    contentText: {
        fontSize: "16px",
        fontWeight: 400,
        color: colors.supportBlack,
        marginBottom: "16px",
    },
    date: {
        color: colors.supportDarker,
        fontWeight: 400,
        fontSize: "16px"
    },
    category: {
        marginLeft: "8px",
        backgroundColor: colors.supportLight,
        borderRadius: "16px",
        padding: "2px 8px 4px 8px",
        fontWeight: 500,
        fontSize: "14px",
        color: colors.supportBlack
    },
    delete: {
        padding: "3px 8px 3px 8px",
        width: "30px",
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.primaryLight,
        borderRadius: "4px",
        border: "none",
        transition: "width 0.5s",
        overflow: "hidden",
        cursor: "pointer"
    },
    deleteHovered: {
        padding: "3px 8px 3px 8px",
        width: "110px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        backgroundColor: colors.primaryLight,
        borderRadius: "4px",
        border: "none",
        transition: "width 0.5s",
        overflow: "hidden",
        cursor: "pointer"
    },
    deleteClicked: {
        padding: "3px 8px 3px 8px",
        width: "230px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        backgroundColor: colors.supportDanger,
        borderRadius: "4px",
        border: "none",
        transition: "width 0.5s",
        overflow: "hidden",
        cursor: "pointer"
    },
    text: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        marginRight: "12px",
        whiteSpace: "nowrap",
        width: 0,
        overflow: "hidden"
    },
    textHovered: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        marginLeft: "12px",
        whiteSpace: "nowrap",
        width: "180px",
        overflow: "hidden"
    },
    textClicked: {
        fontWeight: "600",
        fontSize: "16px",
        marginLeft: "12px",
        whiteSpace: "nowrap",
        width: "200px",
        overflow: "hidden",
        color: "white"
    },
}