import React, {useCallback, useEffect, useRef} from "react";
import {style} from "./style";
import {FilterContent} from "../../FilterByNew";
import {Col} from "react-bootstrap";
import Loader from "../../../../../common/Loader/Loader";
import {colors} from "../../../../../common/colors";

type Props = {
    title: string,
    filterName: string,
    selectable: FilterContent[],
    selected: FilterContent[],
    setSelected: (filterName: string, selectedFilters: FilterContent[]) => void
}

export default function SearchAuto({title, filterName, selectable, setSelected, selected}: Props) {
    const [searchingWord, setSearchingWord] = React.useState<string>("")
    const [indexesAssociated, setIndexesAssociated] = React.useState<number[]>([])
    const [pagination, setPagination] = React.useState<number[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [hasMore, setHasMore] = React.useState<boolean>(false)
    const [page, setPage] = React.useState<number>(0)
    const observer = useRef<IntersectionObserver | null>()
    const lastElement = useCallback((node: HTMLDivElement) => {
        if (observer.current)
            observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevState => prevState + 1)
            }
        })
        if (node) observer.current?.observe(node)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMore])

    useEffect(() => {
        if (page === 0) {
            setPagination(indexesAssociated.slice(page * 500, (page + 1) * 500))
        } else {
            setPagination(prevState => [...prevState, ...indexesAssociated.slice(page * 500, (page + 1) * 500)])

        }
        setHasMore(indexesAssociated.length >= (page + 1) * 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, indexesAssociated])

    useEffect(() => {
        setLoading(true)
        setIndexesAssociated([])
        searchWord("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterName])

    const searchWord = (word: string) => {
        setLoading(true)
        const indexes: number[] = []
        // eslint-disable-next-line array-callback-return
        selectable.map((element, index) => {
            if (word === "")
                indexes.push(index)
            else {
                const result = element.name.toLowerCase().search(word.toLowerCase())
                if (result >= 0) {
                    indexes.push(index)
                }
            }
        })
        setIndexesAssociated(indexes)
        setLoading(false)
        setPage(0)
    }

    const setWord = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchingWord(event.target.value)
        searchWord(event.target.value)
    }

    const addSelected = (filterToAdd: FilterContent) => {
        const index = selected.findIndex((element) => element.id === filterToAdd.id)
        if (index === -1) {
            setSelected(filterName, [...selected, filterToAdd])
        }
    }

    return (
        <div style={style.content}>
            <div style={style.header}>
                <h3 style={style.title}>{title}</h3>
            </div>
            <div style={style.scrollManager}>
                <div style={style.box}>
                    <Col style={{height: "100%"}}>
                        <input type={"text"} style={style.input}
                               placeholder={"Digita per il completamento automatico"}
                               value={searchingWord}
                               onChange={setWord}/>
                        <div style={style.searchResults}>
                            <h5 style={style.searchResultsTitle}>{`RISULTATI PER '${searchingWord}'`}</h5>
                            <div style={{overflowY: "scroll", overflowX: "hidden", flex: 1}}>
                                {pagination.map((number, index) => {
                                    let indexOf = selectable[number]?.name.toUpperCase().indexOf(searchingWord.toUpperCase())
                                    let name = selectable[number]?.name
                                    return <div key={`foundSearch-${number}`}
                                                ref={pagination.length === index + 1 ? lastElement : null}>
                                        <div style={style.selected}
                                             onClick={() => addSelected(selectable[number])}>
                                            {indexOf === -1 ? <div>{name}</div>
                                                : <div>
                                                    {name.substring(0, indexOf)}
                                                    <span style={{backgroundColor: colors.primaryLightOpacity}}>
                                                        {name.substring(indexOf, indexOf + searchingWord.length)}
                                                    </span>
                                                    {name.substring(indexOf + searchingWord.length)}
                                                </div>}
                                            {filterName === "authors" ?
                                                selectable[number]?.sources?.split(",").map((source, index) => {
                                                    if (selectable[number].associatedSources !== undefined) {
                                                        return <div key={`associatedSourcesSearch-${index}`}><span style={style.authorSpan}>
                                                            {/*@ts-ignore*/}
                                                            {source}{`, ${selectable[number].associatedSources[index].type}`}
                                                        </span></div>
                                                    }
                                                    return null
                                                })
                                                : null}
                                            {filterName === "entities" && selectable[number]?.type !== undefined ?
                                                <div>
                                                    <span style={style.authorSpan}>{selectable[number]?.type}</span>
                                                </div>
                                                : null}
                                            {filterName === "sources" && selectable[number]?.type !== undefined ?
                                                <div>
                                                    <span style={style.authorSpan}>{selectable[number]?.type}</span>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                })}
                                {loading ? <Loader/> : null}
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        </div>
    );
}
