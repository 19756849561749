import React from "react";
import {style} from "./style";

type Props = {
    numberOfDots: number,
    index: number,
    className?: string
}

export default class CarouselIndicator extends React.Component<Props, any> {
    render() {
        const indicators = []
        for (let i = 0; i < this.props.numberOfDots; i++) {
            indicators.push(<div key={`indicator-${i}`} style={i <= this.props.index ? style.indicatorSelected : style.indicator}/>)
        }
        return (
            <div className={this.props.className} style={style.box}>
                {indicators}
            </div>
        );
    }
}