import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    slide: {
        width: "50%",
        height: "100%",
        backgroundColor: colors.primaryLight,
        // position: "relative",
        position: "fixed",
        borderBottomLeftRadius: "48px",
        borderTopLeftRadius: "48px"
    },
    slideContent: {
        minWidth: "60%",
        maxWidth: "468px",
        left: "72px",
        bottom: "113px",
        position: "absolute",
        marginRight: "72px"
    },
    shape: {
        marginTop: "9px"
    },
    title: {
        position: "absolute",
        width: "fit-content",
        margin: 0,
        top: 0,
        fontSize: "48px",
        fontWeight: 700,
        fontFamily: "'Literata', serif",
        lineHeight: "71.28px"
    },
    text: {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "27px",
        letterSpacing: "0em",
        textAlign: "left",
    }
}