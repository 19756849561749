import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    backDrop: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        zIndex: "15000",
    },
    modal: {
        position: "fixed",
        top: "4px",
        right: "16px",
        width: "fit-content",
        backgroundColor: colors.supportWhite,
        borderRadius: "24px",
        padding: "12px 8px 12px 8px",
        boxShadow: "0px 3px 10px 0px #3636361C",
        minWidth: "259px",
        userSelect: "none",
    },
    userIcon: {
        backgroundColor: colors.supportWhite,
        height: "48px",
        width: "48px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "20px"
    },
    userPicture: {
        height: "48px",
        width: "48px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:colors.supportWhite
    },
    name: {
        fontSize: "16px",
        fontWeight: 400,
        color: colors.supportBlack
    },
    separator: {
        padding: "0 8px 16px 8px",
        borderBottom: `1px solid ${colors.supportLight}`
    },
    edit: {
        padding: "16px 8px 16px 8px",
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportBlack,
        textDecoration: "none",
        width: "100%",
        display:"flex"
    },
    button: {
        margin: "12px 8px 0 8px",
        fontSize: "16px",
        fontWeight: 600,
        color: colors.supportBlack,
        padding: "12px 16px 12px 16px",
        border: `1px solid ${colors.supportLight}`,
        boxShadow: "0px 2px 1px 0px #1018280D",
        outline: "none",
        backgroundColor: colors.supportWhite,
        borderRadius: "8px"
    },
    version:{
        fontWeight:300,
        fontSize:"12px",
        color:colors.supportBlack,
        margin: "12px 8px 0 8px",
    }
}