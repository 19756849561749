import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store'

interface UserAuthorizationsState {
    value: number[]
}

const initialState: UserAuthorizationsState = {
    value: [],
}

export const userAuthorizationsSlice = createSlice({
    name: 'userAuthorizations',
    initialState,
    reducers: {
        setAuthorizations: (state, action: PayloadAction<number[]>) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setAuthorizations} = userAuthorizationsSlice.actions

export const selectCount = (state: RootState) => state.userAuthorizations.value

export default userAuthorizationsSlice.reducer