import React from "react";
import active from '../../../../assets/png/activeRequirement.png';
import inactive from '../../../../assets/png/inactiveRequirement.png';
import {style} from "./style"

type Props = {
    active: boolean,
    text: string,
    className?: string,
}
export default class LoginPasswordRequirements extends React.Component<Props, any> {
    render() {
        return (
            <div className={this.props.className} style={style.box}>
                <img style={style.image} src={this.props.active ? active : inactive} alt={"requirement"}/>
                <span style={style.text}>{this.props.text}</span>
            </div>
        );
    }
}