import React from "react";
import LoginTitle from "../../components/LoginTitle/LoginTitle";
import LoginSubtitle from "../../components/LoginSubtitle/LoginSubtitle";
import LoginButton from "../../components/LoginButton/LoginButton";
import {style} from "./style";

type Props = {
    nextSlide: () => void
}
export default class Detail1 extends React.Component<Props, any> {
    render() {
        return (
            <div style={style}>
                <LoginTitle className={"mb24"} text={"Welcome to Point-out"}/>
                <LoginSubtitle className={"mb32"}
                               text={"Siamo felici di conoscerti! Rispondi ad alcune domande al fine di fornirti l’esperienza di utilizzo migliore possibile."}/>
                <LoginButton className={"mb32"} text={"Start"} onClick={this.props.nextSlide}/>
            </div>
        );
    }
}