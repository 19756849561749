import CSS from "csstype";
import {colors} from "../../../../common/colors";

export const style: { [key: string]: CSS.Properties } = {
    box: {
        padding: "32px 32px 32px 32px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflowY: "scroll"
    },
    title: {
        fontWeight: "700",
        fontSize: "32px",
        color: colors.supportBlack,
        margin: 0,
    },
    save: {
        border: "none",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: colors.primaryLight,
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack
    },
    saveDisabled: {
        border: "none",
        borderRadius: "8px",
        padding: "12px 16px 12px 16px",
        backgroundColor: colors.primaryLightOpacity,
        boxShadow: "0px 2px 1px 0px #1018280D",
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportDark
    },
    subtitle: {
        fontWeight: "700",
        fontSize: "24px",
        color: colors.supportBlack,
        margin: 0,
        marginBottom: "32px"
    },
    userIcon: {
        backgroundColor: colors.supportWhite,
        height: "90px",
        width: "90px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    userPicture: {
        height: "90px",
        width: "90px",
        border: `2px solid ${colors.supportBlack}`,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    upload: {
        fontWeight: "600",
        fontSize: "16px",
        color: colors.supportBlack,
        border: `1px solid ${colors.supportLight}`,
        borderRadius: "8px",
        boxShadow: "0px 2px 1px 0px #1018280D",
        outline: "none",
        backgroundColor: colors.supportWhite,
        // height: "fit-content",
        padding: "12px 16px 12px 16px",
        marginLeft: "32px",
        cursor: "pointer"
    }
}